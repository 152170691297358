$alternating-feature-break-one: 1200px;
$alternating-feature-break-two: 800px;
$alternating-feature-break-three: 400px;

.alternating-feature {
	img {
		display: block;
		width: 100%;
		opacity: 0;
	}


	&__item {
		display: flex;
		flex-wrap: wrap;
		background-color: #F1F1F1;

		> * {
			transition: transform 0.6s, opacity 0.4s;
		}

		&--flip {
			@media (min-width: $alternating-feature-break-two) {
				flex-direction: row-reverse;
			}
		}

		@media (min-width: 800px) {
			&--viewport-out {
				> * {
					transform: translateY(100px);
					opacity: 0;
				}
				
			}

			&--viewport-in {
				> * {
					transform: translateY(0);
					opacity: 1;
				}
			}
		}
	}

	&__image {
		background-position: 50%;
		background-size: cover;
	}

	&__image,
	&__text {
		width: 100%;
		display: block;

		@media (min-width: $alternating-feature-break-two) {
			width: 50%;
		}
	}

	&__text {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1.75rem 1.75rem 2.5rem;
		transition-delay: 0.1s;

		@media (min-width: $alternating-feature-break-three) {
			padding: 2rem 3rem 3rem;
		}

		@media (min-width: $alternating-feature-break-two) {
			padding: 2rem 3rem;
		}
	}

	&__text-inner {
		max-width: 620px;
		flex: 1;
	}

	&__heading {
		margin: 0;
		font-size: 1.8rem;

		@media (min-width: $alternating-feature-break-one) {
			font-size: 2rem;
		}
	}

	&__copy {
		font-size: 1.2rem;

		@media (min-width: $alternating-feature-break-one) {
			font-size: 1.4rem;
		}
	}

	&__subheading {
		font-size: 1.2rem;
		font-weight: 600;
		text-transform: none;
		margin-bottom: 0;

		@media (min-width: $alternating-feature-break-one) {
			font-size: 1.4rem;
		}
	}

	&__solutions {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-wrap: wrap;
	}

	&__solutions-item {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		width: 100%;
		margin-top: 1rem;

		@media (min-width: $alternating-feature-break-three) {
			flex: 0;
			width: unset;
		}

		&:not(&:last-of-type) {
			margin-right: 1.5rem;
		}

		p {
			font-size: 0.87rem;
			line-height: 1.2;
			margin: 0;

			@media (min-width: $alternating-feature-break-three) {
				max-width: 6.25rem;
				min-width: 12ch;
				flex: 0;
			}
		}
	}

	&__icon {
		width: 1.8rem;
		margin-right: 0.6rem;
		display: flex;
		flex-direction: row;
		justify-content: center;

		img,
		svg {
			width: 100%;

			path {
				fill: #B7CF36;
			}
		}
	}

	&__button {
		margin-top: 2.5rem;
	}
}
