.filters {
  text-align: center;

  &__legend {
    font-family: "Exo-2", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
  }

  &--mob-full {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;

    li,
    .button {
      width: 100%;
    }
  }

  @media only screen and (min-width: 28.75rem) {
    &--mob-full {
      li,
      .button {
        width: auto;
      }
    }
  }
}