.newsFeed {
	max-width: 550px;
	margin: 2rem auto;

	&__item {
		&:last-of-type {
			hr {
				opacity: 0;
			}
		}
	}

	hr {
		height: 10px;
		border: 0;
		background-position: 50%;
		background-size: 15px;
		background-repeat-y: no-repeat;
		background-repeat-x: repeat;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 3'%3E%3Ccircle fill='rgba(#{red($brand-tertiary)}, #{green($brand-tertiary)}, #{blue($brand-tertiary)}, .99999)' cx='1.5' cy='1.5' r='1.5'/%3E%3C/svg%3E");
		margin-bottom: 2rem;
	}

	&__heading {
		text-transform: none;
		font-size: 1.5rem;
		margin: 0;
	}

	&__date {
		text-transform: none;
		font-size: 0.9rem;
		font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
		margin: 0;
	}

	&__iconRow {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 0.7rem;
	}

	&__iconRowText {
		width: 90%;
		padding-left: 1.3rem;
		margin-top: -3px;
	}

	&__icon {
		width: 10%;
	}

	&__intro {
		margin: 0 0 2rem;
	}

	&__link {
		color: #4f575c;
		font-weight: 600;
		padding-left: 6px;
	}
}
