.copy-block {

    +.vid-block {
        margin-top: 2rem;
    }

    &--grey {
        padding: 4rem 0;

        @include media-breakpoint-up(md) {
            padding: 6rem 0;
        }
    }
    
    &--large-text {
        p {
            @media (min-width: 500px) {
                font-size: 1.25rem;
            }
        }
    }

    h2 {
        + p {
            margin-top: -.75rem;
        }
    }

    + .testimonials {
        margin-top: 2rem;
    }
}