.info-cards {
	margin: 2rem 0;

	&__container {}

	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: normal;
		margin: 0 -0.9rem;
		list-style-type: none;
		padding: 0;
	}

	&__item {
		width: calc(100% - 1.8rem);
		margin: 0 0.9rem 1.8rem;
		position: relative;
		padding-bottom: 3rem;
		height: fit-content;

		&::before {
			display: none;
		}

		&--no-link {
			padding-bottom: 0;

			.info-cards__link,
			.info-cards__button {
				display: none;
			}
		}

		&--quote {
			padding-bottom: 3rem;

			.info-cards__button--secondary {
				display: block;
			}
		}

		@media (min-width: 500px) {
			width: calc(50% - 1.8rem);
		}

		@media (min-width: 700px) {
			width: calc(33.33% - 1.8rem);
		}

		@media (min-width: 1000px) {
			width: calc(25% - 1.8rem);
		}
	}

	&__modal {
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
		padding: 2rem;
		background: rgba(0,0,0,0.6);
		justify-content: center;
		align-items: center;

		&.active {
			display: flex;
		}
	}

	&__modal-content {
		position: relative;
		text-align: center;
		padding: 2.8rem 1.8rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-color: $lightgrey;
		background-image: url('/img/testimonial-bg-01.png'), url('/img/testimonial-bg-02.png'), url('/img/testimonial-bg-02.png');
		background-position: calc(100% + 3.5rem) calc(0% - 2.5rem), calc(0% - 3.5rem) 100%, calc(0% - 2rem) calc(0% + 1.5rem);
		background-size: 10rem, 6.5rem, 5rem;
		background-repeat: no-repeat;
		max-width: 46rem;
	}

	&__image {
		display: block;
		width: 100%;
		aspect-ratio: 1/1;

		img {
			display: block;
			width: 100%;
		}
	}

	&__logo {
		display: block;
		width: 100%;

		img {
			display: block;
			max-width: 6rem;
			max-height: 6rem;
			margin: auto;
		}
	}


	&__quote {
		margin-top: 1.3rem;

		> * {
			text-align: center;
			font-family: $exo-2;
			font-weight: 600;
			color: #6A7074;
			margin: 0;
		}
	}

	&__heading {
		font-size: 1.3rem;
		margin: 0.5ex 0 0;
	}

	&__categories {
		list-style-type: none;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		position: relative;
		z-index: 2;
	}

	&__category-item {
		display: block;

		&::before {
			display: none;
		}
	}

	&__category-link {
		display: block;
		font-size: 0.8rem;
		background-color: $lightgrey;
		text-decoration: none;
		padding: 0.3ex 0.8ex;;
		border-radius: 0.6ex;
		margin: 1.2ex 1.2ex 0 0;
		font-family: $exo-2;
		transition: 0.4s;

		&:hover,
		&:focus {
			background-color: $brand-secondary;
			color: $lightgrey;
			outline: unset;
		}
	}

	&__intro {
		font-family: $exo-2;
		margin: 1.2ex 0 0;
		font-size: 1rem;
		color: #707070;
	}

	&__button,
	&__button--secondary {
		margin-top: 2ex;
		font-family: $exo-2;
		font-weight: 600;
		text-decoration: none;
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: $brand-primary;
		border: 1px solid $brand-primary;
		color: $white;
		border-radius: 2ex;
		appearance: none;
		cursor: pointer;
		padding: 0.2ex 2ex 0.5ex;
		transition: ease 0.4s;

		&:hover {
			background-color: $white;
			color: $brand-primary;
		}
	}

	&__button--secondary {
		display: none;
		background-color: $white;
		color: $brand-primary;

		&:hover {
			background-color: $brand-primary;
			color: $white;
		}
	}

	&__button--close,
	&__button-background-close {
		appearance: none;
		background: transparent;
		border: unset;
		margin: 0;
		padding: 0;
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}

	&__button--close {
		top: 1rem;
		right: 1rem;
		width: 1.5rem;
		height: 1.5rem;
	}

	&__link,
	&__modal-toggle {
		appearance: none;
		background: unset;
		border: unset;
		margin: 0;
		padding: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		cursor: pointer;

		&:hover,
		&:focus {
			+.info-cards__button {
				background-color: $white;
				color: $brand-primary;
			}

			+.info-cards__button--secondary {
				background-color: $brand-primary;
				color: $white;
			}
		}

		&:focus {
			outline-color: $brand-primary;
			outline-offset: 5px;
		}
	}

	&__pagination {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: 3rem;
	}

	&__pagination-link {
		margin: 0 1rem;
		padding: 0 1.5rem 0 0;
		position: relative;
		text-decoration: none;

		&::before {
			content: "";
			position: absolute;
			pointer-events: none;
			top: 0;
			right: 0;
			width: 1.5rem;
			height: 1.5rem;
			background-image: url('/img/module-arrow.png');
			background-size: 0.8rem;
			background-repeat: no-repeat;
			background-position: 50%;
			transition: 0.4s;
		}

		&:hover {
			&::before {
				transform: translateX(0.4ex);
			}
		}

		&--prev {
			padding: 0 0 0 1.5rem;

			&::before {
				transform: rotate(180deg);
				right: unset;
				left: 0;
			}

			&:hover {
				&::before {
					transform: rotate(180deg) translateX(0.4ex);
				}
			}
		}
	}

	&__no-results {
		max-width: 80ch;
		margin-inline: auto;
		font-size: 1.1rem;
		text-align: center;
	}

	@media only screen and (min-width: 500px) {
		&__no-results {
			font-size: 1.4rem;
		}
	}
}

