@keyframes shimmer {
    100% {
        mask-position: left;
        -webkit-mask-position: left;
    }
  }

.shimmer-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 1.8rem;
}

.shimmer-card {
    &__img,
    &__pill,
    &__title {
        mask: linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
        -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
        background-repeat: no-repeat;
        animation: shimmer 2s infinite;
        background-color: #f1f0f1;
    }

    &__img {
        width: 100%;
        aspect-ratio: 1/1;
    }

    &__title,
    &__pill {
        display: block;
        border-radius: 0.4rem;
    }

    &__title {
        width: 75%;
        height: 1.875rem;
        margin-block-start: 0.8rem;
    }

    &__pill {
        width: 5.5rem;
        height: 1.375rem;
        margin-block-start: 0.5rem;
    }

    &--info {
        width: calc(100% - 1.8rem);
        height: 25rem;
    }

    @media only screen and (min-width: 31.25rem) {
        &--info {
            width: calc(50% - 1.8rem);   
        }
    }

    @media only screen and (min-width: 43.75rem) {
        &--info {
            width: calc(33.33% - 1.8rem);
        }
    }

    @media only screen and (min-width: 62.5rem) {
        &--info {
            width: calc(25% - 1.8rem);
            height: 27.6875rem;
        }
    }
}