.contact-cards {
	margin-bottom: 3rem;

	&__container {}

	&__heading {
		text-align: center;
	}

	&__columns {
		list-style: none;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0 -0.9rem;
	}

	&__item {
		width: calc(100% - 1.8rem - 1px);
		margin: 1.8rem 0.9rem 0;
		border-left: 1px solid #B6CE35;
		padding: 0 1.1rem 1.1rem;

		@media (min-width: 500px) {
			width: calc(50% - 1.8rem - 1px);
		}

		@media (min-width: 950px) {
			width: calc(25% - 1.8rem - 1px);
		}
	}

	&__item-heading {
		margin: -0.7ex 0 0;
		font-size: 1.25rem;
	}

	&__item-copy {
		margin: 0.5ex 0 1.2ex;
	}

	&__link {
		margin: 1.5ex 0 0;
		width: 100%;
		display: block;
		font-family: "Exo", "Open Sans";
		color: #818181;
		position: relative;
		padding-left: 4ex;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&::before {
			content: "";
			position: absolute;
			top: 0.2ex;
			left: 0;
			width: 3ex;
			aspect-ratio: 1;
			background-size: 2.5ex;
			background-position: 50%;
			background-repeat: no-repeat;
		}

		&--site {
			&::before {
				background-image: url('/img/icon-contact-globe.svg');
			}
		}

		&--number {
			&::before {
				background-image: url('/img/icon-contact-phone.svg');
			}
		}

		&--email {
			&::before {
				background-image: url('/img/icon-contact-email.svg');
			}
		}

		&--directions {
			&::before {
				background-image: url('/img/icon-contact-pin.svg');
			}
		}

	}
}
