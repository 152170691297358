.testimonials {
	margin-bottom: 5rem;
	text-align: center;

	+.latest-news {
		margin-top: -3rem;
	}

	li {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	img {
		height: auto;
    margin: 0 auto 1.6rem;
    max-width: 195px;
    width: 100%;
	}

	p {
		font-size: 1.1rem;
    font-weight: 800;
    line-height: 1.2;
    font-family: Exo-2,Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif;
    text-transform: none;
    margin: 0 0 2.1rem;
    max-width: 800px;
	}

	&__wrapper {
		overflow: hidden;
		margin-block: 1.6rem;
	}

	&__caption {
		@include font-preset--9;

		width: 100%;
		display: block;
	}

	&__button {
		font-size: 18px;
	}

	.slick-dots {
		list-style: none;
		margin-top: 1.8rem;
		padding: 0;
		display: flex;
		justify-content: center;
		width: 100%;

		span {
			width: 10px;
			height: 10px;
			margin: 0 0.4rem;
			background-color: #4f575c;
			font-size: 0;
			opacity: 1;

			&.swiper-pagination-bullet-active {
					background-color: #B7CF36;
			}
		}
	}

	@media only screen and (min-width: 500px) {
		p {
			font-size: 1.8rem;
		}

		&__caption {
			font-size: 18px;
		}
	}
}