.jobListing {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	&__item {
		width: 33.3333%;
		padding: 1rem;
	}

	&__content {
		background-color: #fff;
		height: 100%;
		padding: 2rem;
		text-align: center;

		p {
			margin: 0 0 2rem;
		}
	}

	&__heading {
		font-size: 22px;
	}

	@media (max-width: 780px) {
		&__item {
			width: 100%;
		}
	}
}
