.column.column--disabled {
	display: none;

	@include media-breakpoint-up(md) {
		display: inline-block;
		opacity: .5;
		transform: scale(0.75);

		.tooltip-trigger {
			cursor: default;

			&:hover {
				.tip-container {
					display: none;
				}
			}
		}

		.tooltip-content {
			border: 0;

			&::before {
				color: $base-font-color;
			}
		}
	}
}

.column--transition {
	transition: all .35s ease;
}