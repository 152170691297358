.appBanner {
	background: #000 url('/uploads/images/bg-the-hub-hub-app.jpg') 50% / cover no-repeat;
	text-align: center;
	color: #fff;
	padding: 50px 20px;
	position: relative;
	margin: 50px 0 0;

	&::after {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0.4);
		z-index: 1;
		content: '';
	}

	&__content {
		position: relative;
		z-index: 2;
	}

	&__icons {
		margin: 30px 0 0;
		padding: 0;
		list-style: none;

		li {
			display: inline;
			margin: 0 10px;
		}
	}

	&__heading {
		width: 100%;
		margin: 0;
	}

	&__copy {
		margin: 0;
	}
}
