.bg {
	&--primary {
		color: $white;
		background: $brand-primary;
	}

	&--secondary {
		color: $white;
		background: $brand-secondary;

		.button-secondary-outline {
			border-color: #fff!important;
		}
	}

	&--tertiary {
		color: $white;
		background: $brand-tertiary;
	}

	&--quaternary {
		color: $white;
		background: $brand-quaternary;
	}

	&--quintary {
		color: $white;
		background: $brand-quintary;
	}
	
	&--white {
		color: $grey;
		background: $white;
	}

	&--grey {
		color: $white;
		background: $grey;
	}

	&--justlightgrey {
		color: $grey;
		background: $lightgrey;
	}

	&--lightgrey {
		background: $lightgrey;
		position: relative;

		&::before,
		&::after {
			position: absolute;
			content: '';
			width: 100%;
			height: 20px;
			left: 0;
			right: 0;
		}

		&::before {
			top: 0;
			background: linear-gradient(to bottom, $white 0%, rgba($lightgrey, 0) 100%);
		}

		&::after {
			bottom: 0;
			background: linear-gradient(to bottom, rgba($lightgrey, 0) 0%, $white 100%);
		}

		&.bg--hexes {
			padding: 75px 0;
			background: $lightgrey url('/img/bg-hexes.png') center / cover no-repeat;
		}

		+ .dotted-arrow {
			&::before {
				position: absolute;
				content: '';
				width: 100%;
				height: 20px;
				left: 0;
				right: 0;
				background: linear-gradient(to bottom, rgba($lightgrey, 0) 0%, $white 100%);
				top: -20px;
			}
		}
	}
}

.col-bg {
	&--grey {
		background: $brand-the-hub-bg;
	}

	&--green {
		background: $brand-online-reviews-bg;
	}

	&--yellow {
		background: $brand-guest-feedback-bg;
	}

	&--blue {
		background: $brand-mystery-guest-bg;
	}

	&--brown {
		background: $brand-employee-surveys-bg;
	}
}
