.quote {
	background: $brand-secondary;
	border-bottom: solid 1px $brand-primary;
	border-top: solid 1px $brand-primary;
	color: $white;
	text-align: center;
	padding: $gutter*2 $gutter;

	&__quote {
		font-family: $exo;
		margin: 10px auto;
		@include resp-font-size(20, 24, 28, 32);

		@include media-breakpoint-up(md) {
			max-width: 70%;
		}
	}

	&__meta {
		opacity: .8;
		font-size: 18px;
	}
}
