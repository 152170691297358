.button {
	display: inline-block;
	padding: 4.5px 19px;
	text-decoration: none;
	font: 21px $exo;
	border-radius: 19px;
	border: 1px solid $brand-primary;
	background: $brand-primary;
	color: $white;
	cursor: pointer;
	transition: all 500ms;

	&--small {
		font: 21px $exo;
	}

	&--medium {
		font: 27px $exo;
	}

	&--large {
		font: 33px $exo;
	}

	&:hover {
		background: $white;
		color: $brand-primary;
		transition: all 500ms;
	}

	&.button-primary {
		border-color: $brand-primary;
		background: $brand-primary;
		color: $white;

		&:hover {
			background: $white;
			color: $brand-primary;
		}

		&-outline,
		&.button-primary-outline {
			background: $white;
			color: $brand-primary;

			&:hover {
				background: $brand-primary;
				color: $white;
			}
		}
	}

	&.button-secondary {
		border-color: $brand-secondary;
		background: $brand-secondary;
		color: $white;

		&:hover {
			background: $white;
			color: $brand-secondary;
		}

		&-outline {
			border-color: $brand-secondary;
			background: $white;
			color: $brand-secondary;

			&:hover {
				background: $brand-secondary;
				color: $white;
			}
		}

		&.button-secondary-outline {
			background: $brand-secondary;
			color: $white;

			&:hover {
				background: $brand-secondary;
				color: $white;
			}
		}
	}

	&.button-tertiary {
		border-color: $brand-tertiary;
		background: $brand-tertiary;
		color: $white;

		&:hover {
			background: $white;
			color: $brand-tertiary;
		}
	}

	&.button-white {
		border-color: $white;
		background: $white;
		color: $brand-primary;

		&:hover {
			background: $brand-primary;
			color: $white;
		}
	}

	&--grey {
		border-color: $white;
		background: $white;
		color: $brand-the-hub-bg;

		&:hover {
			background: $brand-the-hub-bg;
			color: $white;
		}
	}

	&--green {
		border-color: $brand-online-reviews-bg;
		background: $brand-online-reviews-bg;
		color: $white;

		&:hover {
			background: $white;
			color: $brand-online-reviews-bg;
		}
	}

	&--yellow {
		border-color: $brand-guest-feedback-bg;
		background: $brand-guest-feedback-bg;
		color: $white;

		&:hover {
			background: $white;
			color: $brand-guest-feedback-bg;
		}
	}

	&--blue {
		border-color: $brand-mystery-guest-bg;
		background: $brand-mystery-guest-bg;
		color: $white;

		&:hover {
			background: $white;
			color: $brand-mystery-guest-bg;
		}
	}

	&--brown {
		border-color: $brand-employee-surveys-bg;
		background: $brand-employee-surveys-bg;
		color: $white;

		&:hover {
			background: $white;
			color: $brand-employee-surveys-bg;
		}
	}

	&--tab {
		border-color: $brand-secondary;
		background: $white;
		color: $brand-secondary;

		&:hover {
			background: $brand-secondary;
			color: $white;
		}
	}

	&--tab-hover {
		background: $brand-secondary;
		color: $white;
	}

	&.button-primary-outline-transparent {
		background: transparent;
		color: $brand-primary;

		&:hover {
			background: $white;
		}
	}
}

.cta-area {
	margin: 0 auto;
	text-align: center;
	padding: $gutter 0 0;

	@include media-breakpoint-up(md) {
		width: 95%;
	}
	@include media-breakpoint-up(lg) {
		width: 75%;
	}
	@include media-breakpoint-up(xl) {
		width: 65%;
	}

	&.cta-area-large {
		padding: 75px 0;
	}

	a {
		margin: 0 7px $gutter;
		font-size: 16px;

		@include media-breakpoint-up(md) {
			font-size: 21px;
		}
	}
}
