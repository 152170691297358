.tab-box {
  position: relative;
  isolation: isolate;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 0.375rem;
  overflow: hidden;
  box-shadow: 4px 4px 12px 0px rgba(30, 30, 30, 0.06);
  transition: transform 200ms linear;

  h3,
  h4 {
    text-align: left;
  }

  li {
    list-style-type: disc;
  }

  &__head {
    h3,
    p {
      margin: 0;
    }

    p {
      margin-block-start: 0.38rem;
    }
  }

  &__copy {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    padding: 1.5rem 1.65rem 1rem 1.65rem;
  }

  &__foot {
    min-height: 79.2px;
    padding: 1.6rem;
    background-color: #B6CE35;
    color: #ffffff;
    text-align: center;
  }


  .pricing-tabs__box-row {
    padding: 0;
  }


  /****************************************
  * Copied from pricing tabs
  ----------------------------------------
  * Placed here to allow for agnostic
  * refactoring. Old classes were too 
  * component specific.
  ****************************************/
  &::after {
    content: '';
    position: absolute;
    inset: 0 -45% auto auto;
    width: 100%;
    height: 0;
    padding-block-end: 100%;
    z-index: -1;
    opacity: 0.15;
    transition: transform 0.2s ease;
  }

  &:hover {
    transform: translateY(-15px);

    &::after {
        transform: scale(1.1);
    }
  }

  &--bullets {
    &::after {
      background: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='tasks' class='svg-inline--fa fa-tasks fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%234f575c' d='M139.61 35.5a12 12 0 0 0-17 0L58.93 98.81l-22.7-22.12a12 12 0 0 0-17 0L3.53 92.41a12 12 0 0 0 0 17l47.59 47.4a12.78 12.78 0 0 0 17.61 0l15.59-15.62L156.52 69a12.09 12.09 0 0 0 .09-17zm0 159.19a12 12 0 0 0-17 0l-63.68 63.72-22.7-22.1a12 12 0 0 0-17 0L3.53 252a12 12 0 0 0 0 17L51 316.5a12.77 12.77 0 0 0 17.6 0l15.7-15.69 72.2-72.22a12 12 0 0 0 .09-16.9zM64 368c-26.49 0-48.59 21.5-48.59 48S37.53 464 64 464a48 48 0 0 0 0-96zm432 16H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z'%3E%3C/path%3E%3C/svg%3E") center / contain no-repeat;
    }
  }

  &--bubble {
    &::after {
      background: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='comment' class='svg-inline--fa fa-comment fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%234f575c' d='M256 32C114.6 32 0 125.1 0 240c0 49.6 21.4 95 57 130.7C44.5 421.1 2.7 466 2.2 466.5c-2.2 2.3-2.8 5.7-1.5 8.7S4.8 480 8 480c66.3 0 116-31.8 140.6-51.4 32.7 12.3 69 19.4 107.4 19.4 141.4 0 256-93.1 256-208S397.4 32 256 32z'%3E%3C/path%3E%3C/svg%3E") center / contain no-repeat;
    }
  }

  &--star {
    &::after {
      background: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='star' class='svg-inline--fa fa-star fa-w-18' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3Cpath fill='%234f575c' d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'%3E%3C/path%3E%3C/svg%3E") center / contain no-repeat;
    }
  }

  &--cutlery {
    &::after {
      right: -21%;
      background: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='utensils' class='svg-inline--fa fa-utensils fa-w-13' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 416 512'%3E%3Cpath fill='%234f575c' d='M207.9 15.2c.8 4.7 16.1 94.5 16.1 128.8 0 52.3-27.8 89.6-68.9 104.6L168 486.7c.7 13.7-10.2 25.3-24 25.3H80c-13.7 0-24.7-11.5-24-25.3l12.9-238.1C27.7 233.6 0 196.2 0 144 0 109.6 15.3 19.9 16.1 15.2 19.3-5.1 61.4-5.4 64 16.3v141.2c1.3 3.4 15.1 3.2 16 0 1.4-25.3 7.9-139.2 8-141.8 3.3-20.8 44.7-20.8 47.9 0 .2 2.7 6.6 116.5 8 141.8.9 3.2 14.8 3.4 16 0V16.3c2.6-21.6 44.8-21.4 48-1.1zm119.2 285.7l-15 185.1c-1.2 14 9.9 26 23.9 26h56c13.3 0 24-10.7 24-24V24c0-13.2-10.7-24-24-24-82.5 0-221.4 178.5-64.9 300.9z'%3E%3C/path%3E%3C/svg%3E") center / contain no-repeat;
    }
  }

  &--marker {
    &::after {
      background: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='map-marker-alt' class='svg-inline--fa fa-map-marker-alt fa-w-12' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'%3E%3Cpath fill='%234f575c' d='M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z'%3E%3C/path%3E%3C/svg%3E") center / contain no-repeat;
    }
  }

  &--box {
    &::after {
      background: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='box-open' class='svg-inline--fa fa-box-open fa-w-20' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'%3E%3Cpath fill='%234f575c' d='M425.7 256c-16.9 0-32.8-9-41.4-23.4L320 126l-64.2 106.6c-8.7 14.5-24.6 23.5-41.5 23.5-4.5 0-9-.6-13.3-1.9L64 215v178c0 14.7 10 27.5 24.2 31l216.2 54.1c10.2 2.5 20.9 2.5 31 0L551.8 424c14.2-3.6 24.2-16.4 24.2-31V215l-137 39.1c-4.3 1.3-8.8 1.9-13.3 1.9zm212.6-112.2L586.8 41c-3.1-6.2-9.8-9.8-16.7-8.9L320 64l91.7 152.1c3.8 6.3 11.4 9.3 18.5 7.3l197.9-56.5c9.9-2.9 14.7-13.9 10.2-23.1zM53.2 41L1.7 143.8c-4.6 9.2.3 20.2 10.1 23l197.9 56.5c7.1 2 14.7-1 18.5-7.3L320 64 69.8 32.1c-6.9-.8-13.5 2.7-16.6 8.9z'%3E%3C/path%3E%3C/svg%3E") center / contain no-repeat;
    }
  }

  &--bed {
    &::after {
      background: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='bed' class='svg-inline--fa fa-bed fa-w-20' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'%3E%3Cpath fill='%234f575c' d='M176 256c44.11 0 80-35.89 80-80s-35.89-80-80-80-80 35.89-80 80 35.89 80 80 80zm352-128H304c-8.84 0-16 7.16-16 16v144H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v352c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16v-48h512v48c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V240c0-61.86-50.14-112-112-112z'%3E%3C/path%3E%3C/svg%3E") center / contain no-repeat;
    }
  }

  &--phone {
    &::after {
      right: -22%;
      background: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='phone' class='svg-inline--fa fa-phone fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%234f575c' d='M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z'%3E%3C/path%3E%3C/svg%3E") center / contain no-repeat;
    }
  }

  &--lightbulb {
    &::after {
      right: -48%;
      background-image: url('/img/icon-lightbulb.png');
      background-position: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      opacity: 1;
      width: 110%;
    }
  }

  &--arrowRepeat {
    &::after {
      right: -20%;
      top: -2%;
      background-image: url('/img/icon-arrow-repeat.png');
      background-position: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      opacity: 1;
      width: 92%;
    }
  }

  &--arrowReply {
    &::after {
      right: -14%;
      top: -4%;
      background-image: url('/img/icon-arrow-reply.png');
      background-position: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      opacity: 1;
      width: 95%;
    }
  }
}
