#ccc {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	pointer-events: none;

	&:has(.ccc-banner) {
		background-color: rgba(0,0,0,0.3);
	}

	.ccc-banner {
		pointer-events: all;
		margin: 0;
		padding: 1.5rem;
		width: 100%;
		max-width: calc(100% - 3rem);
		text-align: center;
		background-color: #F1F1F2 !important;
		background-image: url(/img/testimonial-bg-01.png), url(/img/testimonial-bg-02.png), url(/img/testimonial-bg-02.png);
		background-position: calc(100% + 3.5rem) 0, calc(0% - 4rem) 100%, calc(0% - 2rem) calc(0% + 1.5rem);
		background-size: 12rem, 8rem, 5rem;
		background-repeat: no-repeat;

		@media (min-width: 700px) {
			max-width: 620px;
			padding: 2rem 3.5rem 2.5rem;
		}
	}

	.ccc-banner__label-container {
		justify-content: center;
		margin-top: 0;

		@media (min-width: 700px) {
			margin-top: 2rem;
		}
	}

	.ccc-banner__title {
		font-family: "Exo-2", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
		font-size: 1.9rem;
	}

	.ccc-form__label {
		width: auto;
		align-items: center;
	}

	.ccc-banner__description {
		font-size: 14px;

		@media (min-width: 700px) {
			font-size: 16px;
		}
	}

	.ccc-banner__buttons {
		margin-top: -0.9rem;

		@media (min-width: 700px) {
			margin-top: 1rem;
		}

		button {
			margin-top: 1rem;
		}

		a {
			margin-top: 1rem;
		}
	}

	.ccc-banner__button--secondary {
		border-radius: 100px;
		background-color: #F1F1F2;
		border: 1px solid #4F575C;
		color: #4F575C;
		font-family: "Exo-2", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
		font-size: 1rem;
		padding: 1ex 3.5ex 1.3ex;
		text-decoration: none;

		@media (max-width: 395px) {
			margin: 1rem 1rem 0;
		}

		&:hover {
			color: #b6ce35;
			border: 1px solid #b6ce35;
			background-color: #fff;
		}
	}

	.ccc-banner__button--primary {
		border-radius: 100px;
		background-color: #b6ce35 !important;
		border: 1px solid #b6ce35;
		color: #fff;
		font-family: "Exo-2", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
		font-size: 1rem;
		padding: 1ex 3.5ex 1.3ex;
		text-decoration: none;

		&:hover {
			color: #b6ce35 !important;
			border: 1px solid #b6ce35;
			background-color: #fff !important;
		}
	}

}


