/* Sass Variables */

/* Breakpoints */
$grid-breakpoints: (
	xs: 0,
	sm: 568px,
	md: 768px,
	lg: 992px,
	lg2: 1080px,
	xl: 1280px,
	xxl: 1500px
) !default;

/* Container widths */
$container-max-widths: (
	sm: 520px,
	md: 720px,
	lg: 940px,
	xl: 1220px,
	xxl: 1440px
) !default;

$columns: 12;
$gutter: 20px;
$grid-flex: true;

/* Font sizes */
$base-font-size: 16px;
$base-line-height: 1.4;

// Fonts

$sans-serif: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$serif: 'Lora', serif;
$exo: 'Exo', $sans-serif;
$exo-2: 'Exo-2', $sans-serif;

/* Colours */
$base-font-color: #4f575c;
$font-color-two: #4B5459;

$white: #fff;
$black: #000;
$lightgrey: #f1f0f1;
$midgrey: #4f575c;
$grey: #697074;
$darkgrey: #333;

$grey-400: #707070;

$brand-primary: #b6ce35;
$brand-secondary: #4f575c;
$brand-tertiary: #aabebf;
$brand-quaternary: #56B6BD;
$brand-quintary: #879194;

$brand-the-hub-icon: #4f575c;
$brand-the-hub-bg: #929b9f;
$brand-the-hub-light: #f0f0f0;

$brand-guest-feedback-icon: #b19141;
$brand-guest-feedback-bg: #ccb376;
$brand-guest-feedback-light: #eee6d2;

$brand-online-reviews-icon: #74896d;
$brand-online-reviews-bg: #91a38b;
$brand-online-reviews-light: #e6ebe5;

$brand-employee-surveys-icon: #988a66;
$brand-employee-surveys-bg: #b7ac97;
$brand-employee-surveys-light: #e8e4dc;

$brand-mystery-guest-icon: #607776;
$brand-mystery-guest-bg: #7a9393;
$brand-mystery-guest-light: #bfcccc;

$success-green: #50C878;