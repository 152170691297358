.alternating-block {
    color: $font-color-two;
    margin-top: 1.5rem;

    &--with-stat {
        @media (min-width: 992px) {
            .alternating-block__col--image {
                margin-top: 5.5rem;
            }
        }
    }

    &--grey {
        margin: 0 ;
        padding: 4rem 0;

        @include media-breakpoint-up(md) {
            padding: 6rem 0;
        }

        .alternating-block__item {
            margin: 0;
        }

        + .alternating-block--white {
            padding-top: 4rem;

            @include media-breakpoint-up(md) {
                padding-top: 6rem;
            }
        }
    }

    + *:not(.alternating-block) {
        margin-top: 80px;
    }

    &__item {
        margin-bottom: 7rem;

        + .alternating-block__item {
            margin-top: -3rem;
        }
    }

    
    &__content {
        @include row();

        justify-content: space-between;
        align-items: center;
        margin: 0 !important;

        @include media-breakpoint-up(xl) {
            @include row();
            align-items: center;
        }


        &--flip {
            flex-flow: row-reverse wrap;

            .alternating-block__stat {
                left: 20px;
                right: unset;
            }

            @include media-breakpoint-up(lg) {
                .alternating-block__feature-links {
                    width: calc(50% - 1.8rem);
                    margin-right: 0;
                }
            }
        }
    }

    &__col {
        position: relative;
        width: 100%;


        &--image {
            max-width: 600px;
            margin: 3rem auto 0;
        }

        &--image-stat {
            margin: 7rem auto 0;
        }

        &--hex {
            position: relative;
            z-index: 3;

            &::after {
                content: url('/img/hex-decor.svg');
                position: absolute;
                z-index: -1;
                right: -6%;
                bottom: -14%;
                width: 55%;
                max-width: 370px;
                height: auto;
            }
        }

        @media (min-width: 992px) {
            width: calc(50% - 1.8rem);

            &--copy {
                .alternating-block__feature-links {
                    width: 100%;
                }
            }

            &--copy-wide {
                width: calc(60% - 1.8rem); 
            }

            &--image-narrow {
                width: calc(40% - 1.8rem); 
            }
        }
    }

    &__modules,
    &__feature-links {
        list-style: none;
        padding-inline-start: 50px;
        margin: 0 0 -2rem;
    }

    &__modules-item {
        position: relative;
        // margin-bottom: 2rem;

        svg {
            position: absolute;
            left: -50px;
            top: 4px;
            width: 35px;
            max-height: 29px;
            height: inherit;
        }
    }

    &__modules-link {
        display: block;
        text-decoration: none;

        &--link {

            &:hover {
                .alternating-block__modules-heading {
                    &:after {
                        transform: translate(-35%, -50%);
                    }
                }
            }
            
            .alternating-block__modules-heading {
                position: relative;
    
                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: -85px;
                    transform: translate(-50%, -50%);
                    height: 20px;
                    width: 80px;
                    background-image: url('/img/module-arrow.png');
                    background-repeat: no-repeat;
                    background-position: 100%;
                    background-size: contain;
                    transition: all 0.233s ease-out;
                }
            }
        }


    }

    &__modules-heading {
        @include font-preset--5;

        display: inline-block;
    }

    &__feature-links {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 2.1rem;

        @include media-breakpoint-up(lg) {
            justify-content: flex-start;
        }

        .alternating-block__modules-item {
            width: 100%;
        }

    }

    &__stat {
        display: inline-table;
        position: absolute;
        top: -30px;
        right: 20px;
        left: inherit;
        text-align: center;
        z-index: 1;
        min-width: 199px;

        @include media-breakpoint-up(sm) {
            min-width: 265px;
        }

        
        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 130%;
            min-width: 210px;
            height: 210%;
            min-height: 150px;
            transform: translate(-50%,-50%);
            z-index: 1;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' width='200' height='174' viewbox='0 0 200 173.20508075688772' style='filter: drop-shadow(rgba(255, 255, 255, 0) 0px 0px 10px);'%3E%3Cpath fill='%234F575C' d='M5.000000000000001 95.26279441628824Q0 86.60254037844386 5.000000000000001 77.94228634059948L45 8.660254037844386Q50 0 60 0L140 0Q150 0 155 8.660254037844386L195 77.94228634059948Q200 86.60254037844386 195 95.26279441628824L155 164.54482671904333Q150 173.20508075688772 140 173.20508075688772L60 173.20508075688772Q50 173.20508075688772 45 164.54482671904333Z'/%3E%3C/svg%3E") center / contain no-repeat;
        
            @include media-breakpoint-up(md) {
                min-width: 190px;
            }

            @include media-breakpoint-up(xl) {
                height: 230%;
                min-width: 210px;
            }
        }

        &:before {
            top: 63%;
            left: 52%;
            filter: blur(14px);
            transform: translate(-50%, -50%) scale(0.92);
        }
    }

    &__stat-number, &__stat-text {
        position: relative;
        z-index: 2;
        color: $white;
    }

    &__stat-number {
        @include font-preset--3;

        line-height: 1;
        color: $brand-primary;
    }

    &__stat-text {
        @include font-preset--8;

        width: max(60%);
        margin: 7px auto 0;

        @media (max-width: 550px) {
            font-size: 12px;
        }
    }

    &__img {
        width: 100%;
        
        &--hex {
            position: relative;
            width: 100%;
            aspect-ratio: 116/109;
            -webkit-mask-image: url('/img/hex-mask.png');
            mask-image: url('/img/hex-mask.png');
            -webkit-mask-size: 100% 100%;
            mask-size: 100% 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &__heading {
        text-align: center;
        @include font-preset--2;

        @media (min-width: 992px) {
            text-align: left;
        }
    }

    &__intro {
        @include font-preset--7;

        p {
            @include font-preset--7;
        }
    }

    &__modules-intro {
        @include font-preset--7;
    }

    &__sticky-banner {
        position: sticky;
        bottom: 0;
        z-index: 11;

        .cta-bar {
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 50%;
                height: 100%;
                width: 100vw;
                transform: translateX(-50%);
                background: inherit;
                z-index: -1;
            }
        }

        + .cta-bar {
            display: none;
        }
    }

    &__accordion {}

    &__accordion-row {
        margin-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #CACCCE;

        &:last-of-type {
            border-bottom: 0;
        }
    }

    &__accordion-trigger {
        appearance: none;
        padding: 0 1.5rem 0 0;
        margin: 0;
        background: unset;
        border: unset;
        cursor: pointer;
        font-size: 1rem;
        font-weight: 800;
        width: 100%;
        text-align: left;
        color: #4F575C;
        background-image: url('/img/icon-plus-green.png');
        background-position: 100% 50%;
        background-repeat: no-repeat;
        background-size: 1.1rem;

        &.active {
            background-image: url('/img/icon-minus-green.png');
        }

        @media (min-width: 600px) {
            font-size: 1.25rem;
        }
    }

    &__accordion-draw {}
}
