.contact-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	backdrop-filter: blur(6px);
	z-index: 200;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.25rem;

	@media (min-width: 700px) {
		padding: 2rem;
	}

	&__close {
		position: absolute;
		top: 2rem;
		right: 2rem;
		width: 1.5rem;
		aspect-ratio: 1;
		background-image: url('/img/icon-close-black.png');
		background-position: 50%;
		background-size: 1.3rem;
		background-repeat: no-repeat;

		@media (min-width: 700px) {
			background-image: url('/img/icon-close-white.png');
			top: 2rem;
			right: 2rem;
		}
	}

	&__inner {
		background-color: #fff;
		border-radius: 4px;
		padding: 2rem;
		width: 100%;
		max-width: 790px;
		height: 100%;

		@media (min-width: 700px) {
			height: unset;
		}
	}

	&__heading {
		text-align: center;
		margin: 0 0 1ex;
	}

	&__intro {
		text-align: center;
		margin: 0;
	}

	&__form {
		padding: 0.4rem 1.8rem 1.4rem;
		background-color: #F1F0F1;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		border-radius: 4px;
		max-height: calc(100vh - 9rem);
		overflow: scroll;

		.errors {
			list-style: none;
			margin: 0.5rem 0 0;
			padding: 0;
			font-size: 13px;
			color: red;
		}
	}

	&__form-item {
		width: 100%;
		margin-top: 0.8rem;

		&:has(input:focus),
		&:has(textarea:focus) {
			label {
				font-weight: 600;
			}
		}

		label,
		input,
		select,
		textarea {
			font-family: "Exo", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
			font-weight: 400;
			font-size: 14px;
		}

		label {
			width: 100%;

			&.required {
				&::after {
					position: relative;
					content:"*";
					font-size: 12px;
					color: red;
					top: -3px;
					right: -2px;
				}
			}
		}

		select {
			background-color: white;
			border: 0;
			color: #4f575c;
			padding: 8px 7px 7px;
			width: 100%;
		}

		input,
		textarea {
			width: 100%;
			border: 0;
			padding: 0.3rem;

			&:focus {
				outline: 1px solid #4f575c;
			}
		}

		textarea {
			min-height: 120px;
		}

		&--50 {
			@media (min-width: 700px) {
				width: calc( 50% - 1rem);
			}
		}
	}

	.button {
		margin: auto;
		margin-top: 1rem;
		font-size: 17px;
	}

	&__loading-spinner {
		position: absolute;
		top: 0;
		left: 0;
		width: 30px;
		aspect-ratio: 1;
		top: 50%;
		left: 50%;
		margin-top: -10px;
		margin-left: -10px;
		border-radius: 50%;
		border: 2px solid #ccc;
		border-top-color: #000;
		animation: spinner .6s linear infinite;
	}

	&__form-content {
		background-color: #f1f0f1;
		border-radius: 4px;
		padding: .4rem 1.8rem 1.4rem;

		label {
			font-family: Exo, Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
			font-size: 14px;
			font-weight: 400 !important;
		}

		input,
		textarea,
		select {
			border: 0;
    		padding: 0.7ex;
			font-size: 14px;
		}

		button {
			font-size: 17px;
			background: #b6ce35;
			border: 1px solid #b6ce35;
			border-radius: 19px;
			color: #fff;
			cursor: pointer;
			display: inline-block;
			padding: 4.5px 19px;
			text-decoration: none;
			transition: all .5s;
			margin-top: 1ex;

			&:hover {
				background: #fff;
				color: #b6ce35;
			}

		}
	}
}

@keyframes spinner {
	to {transform: rotate(360deg);}
}

