.contact-info,
.reports {
	a[href^="tel:"] {
		text-decoration: none;
		color: $brand-secondary;
	}

	.column.padding {
		padding: 0 $gutter*2;
	}

	.dotty {
		position: relative;

		&::before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 3 '%3E%3Ccircle fill='rgba(#{red($brand-tertiary)}, #{green($brand-tertiary)}, #{blue($brand-tertiary)}, .99999)' cx='1.5' cy='1.5' r='1.5'/%3E%3C/svg%3E") center repeat-x;
			height: 3px;
			width: 100%;
			@include media-breakpoint-up(md) {
				background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 3 10'%3E%3Ccircle fill='rgba(#{red($brand-tertiary)}, #{green($brand-tertiary)}, #{blue($brand-tertiary)}, .99999)' cx='1.5' cy='1.5' r='1.5'/%3E%3C/svg%3E") center repeat-y;
				height: 100%;
				width: 3px;
			}
		}
	}

	h4 {
		margin: 20px 0 0;
	}

	p {
		margin: 0 0 10px;
	}
}
