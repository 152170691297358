.guest-home {
	background: url(/img/bg-guest-home.jpg) top center / contain no-repeat;

	header {
		background: transparent;

		.header-menu,
		.hgem-buttons {
			display: none;
		}

		.guest-buttons {
			display: block;

			li {
				&:first-of-type {
					a {
						background: $brand-tertiary;
					}
				}

				a {
					background: #62bec4;
				}
			}
		}
	}

	.hero {
		color: $white;

		p {
			font-size: 36px;
		}
	}

	h1 {
		max-width: 750px;
		margin-bottom: 35px;
		line-height: 1.3;
	}

	.hero-slides .hero-slide {
		p {
			margin-top: 25px;
			line-height: 1.3;
			max-width: 750px;
		}

		img {
			max-width: 140px;
		}
	}

	.how-it-works {
		.list-icon {
			max-width: 120px;
			margin: 25px auto;
		}

		&.list-blocks li {
			&::before {
				background: #fff;
				height: 100%;
				width: 10px;
				left: 0;
			}

			&::after {
				background: #fff;
				height: 100%;
				width: 10px;
				right: 0;
				border-top: 0;
				border-bottom: 0;
				border-left: 0;
				top: 0;
			}

			&:first-of-type .how-it-works-arrow {
				display: none;
			}
		}

		.how-it-works-arrow {
			border-top: 12px solid transparent;
			border-bottom: 12px solid transparent;
			border-left: 12px solid #f1f0f1;
			top: 152px;
			left: -5px;
			z-index: 10;
			position: absolute;
		}
	}

	.hero.hero-hex-container {
		padding: 0;
	}

	.icon-list-large {
		max-width: calc((220px + 20px * 4) * 3);

		li {
			max-width: 220px;

			img {
				width: 100%;
			}
		}
	}
}
