.hero {
  &--img {
    height: 265px;
  }

  &--hex-container {
    // margin-top: 108.95px;
  }

  @media only screen and (min-width: 992px) {
    &--hex-container {
      // margin-top: 166px;
    }
  }

  @media only screen and (min-width: 1078px) {
    &--hex-container {
      // margin-top: 139px;
    }
  }
}