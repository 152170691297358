.ticklist {
  display: flex;
  flex-flow: column nowrap;
  align-items: normal;
  gap: 2rem;
  width: 100%;
  list-style: none;
  padding: 0;

  li,
  &__item {
    position: relative;
    width: 100%;
    padding-inline-start: 3.125rem;
  }

  h4,
  p {
    margin: 0;
  }

  h4 {
    font-weight: 800;
    margin-block-end: 0.5rem;
  }

  &__tick {
    position: absolute;
    inset: 0.25rem auto auto 0;
    width: 2.1875rem;
    height: auto;
    max-height: 1.815rem;
    color: #BACD52;
  }

  @media only screen and (min-width: 768px) {
    h4 {
      font-size: 1.8rem;
    }
  }
}