html {
	scroll-behavior: smooth;
}

.hero__down-link {
	display: none;
	position: relative;
	bottom: -6rem;

	img {
		max-width: 2.7rem;
	}
}

.home {
	position: relative;
	overflow-x: hidden;

	header {
		background: transparent;
		border-bottom: 0;
		z-index: 2;
	}

	.hero {
		color: $white;
		background-color: #636363;
		background-image: url('/img/people-eating-food.webp');
		background-blend-mode: soft-light;
		background-position: 85% 50%;
		background-size: cover;
		background-repeat: no-repeat;
		padding-top: 13rem;
		z-index: 1;

		&__down-link {
			display: block;
		}

		h1 {
			margin-bottom: 8px;
		}

		@media (min-width: 660px) {
			background-position: 50%;
		}

		@media (min-width: 991px) {
			padding-top: 15rem;
			background-attachment: fixed;

			&__down-link {
				display: none;
			}
		}

		.arrow-down {
			width: 55px;
			transform: rotate(90deg) translateX(200%);
			opacity: 0;
		}

		&.slide-up .arrow-down {
			animation: slideUp 0.7s;
			animation-fill-mode: forwards;
		}

		// &__background {
		// 	position: fixed;
		// 	width: 100%;
		// 	height: 77vh;
		// 	background-image: url('/img/people-eating-food.jpg');
		// 	background-position: 50%;
		// 	background-size: cover;
		// 	z-index: -1;

		// 	&::before,
		// 	&::after, {
		// 		content: "";
		// 		position: absolute;
		// 		top: 0;
		// 		width: 100%;
		// 		height: 77vh;
		// 		background-color: rgba(79, 87, 92, 1);
		// 		background: linear-gradient(to top, rgba(79, 87, 92, 1) 0%, rgba(79, 87, 92, 0) 100%);
		// 	}

		// 	// &::after {
		// 	// 	top: unset;
		// 	// 	bottom: 0;
		// 	// 	height: 15vh;
		// 	// 	background-color: $white;
		// 	// 	background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
		// 	// }
		// }

		&__trusted {
			@include font-preset--4;

			&--desktop {
				display: none;

				@include media-breakpoint-up(lg) {
					display: block;
				}
			}

			&--mobile {
				@include font-preset--4;

				color: $grey;
				margin: 0 0 $gutter;
			}
		}

		&__background-hexagon {
			position: absolute;
			top: 17%;
			left: 19%;
			width: 9vw;

			svg {
				width: 100%;
				height: auto;
			}

			&:nth-of-type(2) {
				top: 31%;
				left: -8%;
				width: 19vw;
			}

			&:nth-of-type(3) {
				top: 30%;
				left: 80%;
				width: 12vw;
			}

			&:nth-of-type(4) {
				top: 79%;
				left: -4%;
				width: 8vw;
			}

			@media (max-width: 768px) {
				display: none;
			}
		}
	}

	@keyframes slideUp {
		0% {
			transform: rotate(90deg) translateX(200%);
			opacity: 0;
		}

		100% {
			transform: rotate(90deg) translateX(0%);
			opacity: 1;
		}
	}

	.columns-step.columns-step-arrow::after {
		left: 0;
		right: 0;
	}
}
