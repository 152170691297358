/****************************************
* Section styling
----------------------------------------
* Tab & pane styling can be found in the
* respective .scss files :)
****************************************/
.content-tabs {
  &__heading {
    padding-block: 2.5rem 2.25rem;
    text-align: center;
  }

  h1 {
    font-size: 2.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
    text-transform: uppercase;
  }

  &__copy {
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
  }

  @media only screen and (min-width: 768px) {
    h1 {
      font-size: 3.4rem;
    }
  }

  @media only screen and (min-width: 1024px) {
    
  }
}
