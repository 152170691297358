.hero {
	text-align: center;
	color: $brand-secondary;
	background-color: #505d65;
	overflow: hidden;
	position: relative;

	@include media-breakpoint-down(sm) {
		// padding: 40px 0;
	}

	&--white {
		color: $white;

		.dotted {
			background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 3'%3E%3Ccircle fill='rgba(#{red($white)}, #{green($white)}, #{blue($white)}, .99999)' cx='1.5' cy='1.5' r='1.5'/%3E%3C/svg%3E") center repeat-x !important;
		}
	}

	&--hex-container {
		overflow: hidden;
		
		// For a nicer pre-load appearance - This gets overwritten by JS on load
		margin-top: -108px;

		@media (min-width: 990px) {
			margin-top: -140px;
		}
		//

		@include media-breakpoint-up(md) {
			padding: 10px 0 0;
		}
		@include media-breakpoint-up(lg) {
			padding: 50px 0 0;
		}
		@include media-breakpoint-up(xl) {
			padding: 100px 0 0;
		}
	}

	&--hex-footer {
		position: relative;

		&:after {
			position: absolute;
			bottom: -1px;
			left: 0;
			height: 100%;
			width: 100%;
			background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1600' height='148.001' viewBox='0 0 1600 148.001'%3E%3Cpath id='Subtraction_1' data-name='Subtraction 1' d='M-2306,244H-3906V166h29.494a20.027,20.027,0,0,0,9.972-2.664,20.026,20.026,0,0,0,7.316-7.281l29.37-50.5a20.063,20.063,0,0,0,2.7-9.555h90.5a40.047,40.047,0,0,0,1.656,8.763,40.053,40.053,0,0,0,3.632,8.307l51.047,88a40.051,40.051,0,0,0,14.636,14.59A40.062,40.062,0,0,0-3645.709,221h102.42a40.061,40.061,0,0,0,19.965-5.338,40.055,40.055,0,0,0,14.635-14.59l51.046-88a40.037,40.037,0,0,0,3.632-8.307A40.037,40.037,0,0,0-3452.355,96h147.215a40.056,40.056,0,0,0,4.783,27.07l51.047,88a40.059,40.059,0,0,0,14.635,14.591A40.055,40.055,0,0,0-3214.711,231h102.421a40.06,40.06,0,0,0,19.965-5.338,40.051,40.051,0,0,0,14.636-14.591l24.4-42.07h45.781a20.029,20.029,0,0,0,9.972-2.664,20.024,20.024,0,0,0,7.316-7.282l29.369-50.5a19.955,19.955,0,0,0,2.32-6.117,19.983,19.983,0,0,0,.234-6.438h122.031l17.478,30.054a20.023,20.023,0,0,0,7.316,7.282,20.031,20.031,0,0,0,9.973,2.664h58.99a20.028,20.028,0,0,0,9.973-2.664,20.036,20.036,0,0,0,7.317-7.282L-2707.738,96h173.6a40.055,40.055,0,0,0,4.783,27.07l51.047,88a40.053,40.053,0,0,0,14.635,14.591A40.055,40.055,0,0,0-2443.711,231h102.421a40.059,40.059,0,0,0,19.965-5.338,40.052,40.052,0,0,0,14.635-14.591l.689-1.188V244Z' transform='translate(3906 -96.001)' fill='%23fff'/%3E%3C/svg%3E%0A") bottom center / contain no-repeat;

			@include media-breakpoint-up(lg) {
				content: '';
			}
		}
	}

	&__overlay {
		.overlay__gradient {
			z-index: 2;
			height: 200px;
			left: 0;
			right: 0;
			bottom: -10%;
			margin: auto;
			position: absolute;
			background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(182, 206, 53, 1) 100%);
			background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(182, 206, 53, 1) 100%);
			background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(182, 206, 53, 1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#b6ce35', GradientType=0);
		}

		.overlay__hexes {
			z-index: 1;
			fill: $white;
			position: absolute;
			left: -20%;
			right: -20%;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 140%;
			opacity: 0.12;
		}
	}

	&__content {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		padding: 0 5%;
		z-index: 5;
		@include media-breakpoint-up(lg) {
			padding: 0 15%;
		}
		@include media-breakpoint-up(xl) {
			padding: 0 20%;
		}

		.container {
			max-width: 75ch;
		}
	}

	&__slides {
		background: #a9bdbe;
		padding: 0 0 $gutter;
	}

	&__slide {
		width: 100%;

		p {
			margin: 0 auto $gutter*2;
		}
	}

	&__counter {
		margin: 0 auto;

		li {
			height: 18px;
			width: 18px;
			border-radius: 50%;
			background: $white;
			margin: 0 2px;
			opacity: .5;

			&.cycle-pager-active {
				opacity: .8;
			}
		}
	}

	h1,
	p {
		margin: 0 auto;
	}

	h1 {
		@include font-preset--1;
	}


	p {
		@include font-preset--6;
	}

	h3 {
		text-transform: none;
	}

	.arrow-down {
		width: 33px;
		transform: rotate(90deg);
		position: relative;
		z-index: 999;
		margin: 33px auto -33px;
		cursor: pointer;
	}
	

	.dotted {
		background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 3'%3E%3Ccircle fill='rgba(#{red($brand-secondary)}, #{green($brand-secondary)}, #{blue($brand-secondary)}, .99999)' cx='1.5' cy='1.5' r='1.5'/%3E%3C/svg%3E") center repeat-x;
	}

	&__hexes {
		display: none;
		position: relative;
		height: 30vw;
		z-index: 10;
		margin: 0 0 -100px;
		top: -50px;

		@include media-breakpoint-up(lg) {
			display: block;
			top: -100px;
		}
		//overflow: hidden;

		.layer {
			width: 100%;
			height: 100%;
			position: relative;
		}

		/* stylelint-disable */
		.layer {
			.hex {
				position: absolute;
				top: percentage(133/450);
				left: (-40/15) + 0%;
			} // one

			+ .layer {
				.hex {
					top: percentage(168/450);
					left: (107/15) + 0%;
				} // three

				+ .layer {
					.hex {
						top: percentage(50/450);
						left: (230/15) + 0%;
					} // four

					+ .layer {
						.hex {
							top: percentage(130/450);
							left: (369/15) + 0%;
						} // six

						+ .layer {
							.hex {
								top: percentage(233/450);
								left: (526/15) + 0%;
							} // eight

							+ .layer {
								.hex {
									top: percentage(274/450);
									left: (633/15) + 0%;
								} // ten

								+ .layer {
									.hex {
										top: percentage(217/450);
										left: (782/15) + 0%;
									} // eleven

									+ .layer {
										.hex {
											top: percentage(133/450);
											left: (887/15) + 0%;
										} // twelve

										+ .layer {
											.hex {
												top: percentage(250/450);
												left: (1025/15) + 0%;
											} // fourteen

											+ .layer {
												.hex {
													top: percentage(73/450);
													left: (1062/15) + 0%;
												} // fifteen

												+ .layer {
													.hex {
														top: percentage(160/450);
														left: (1153/15) + 0%;
													} // sixteen

													+ .layer {
														.hex {
															top: percentage(79/450);
															left: (1388/15) + 0%;
														} // eighteen

														+ .layer {
															.hex {
																top: percentage(60/450);
																left: (100/15) + 0%;
															} // two

															+ .layer {
																.hex {
																	top: percentage(110/450);
																	left: (250/15) + 0%;
																} // five

																+ .layer {
																	.hex {
																		top: percentage(165/450);
																		left: (553/15) + 0%;
																	} // seven

																	+ .layer {
																		.hex {
																			top: percentage(305/450);
																			left: (561/15) + 0%;
																		} // nine

																		+ .layer {
																			.hex {
																				top: percentage(140/450);
																				left: (1010/15) + 0%;
																			} // thirteen

																			+ .layer {
																				.hex {
																					top: percentage(1/450);
																					left: (1183/15) + 0%;
																				} // seventeen

																				+ .layer {
																					.hex {
																						top: percentage(280/450);
																						left: (1440/15) + 0%;
																					} // nineteen
																				}
																			}
																		}
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		/* stylelint-enable */
	}

	&__hexes-slider {
		background: $white;
		padding-top: $gutter*1.5;

		@include media-breakpoint-up(lg) {
			display: none;
		}
	}

	&.js-bg {
		max-height: 265px;
		overflow: hidden;
	}

	.hero-image {
		display: block;
		// margin: 0 auto;
		min-height: 265px;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		width: auto;
		min-width: 100%;

		&--gray {
			opacity: 0.8;
			-webkit-filter: grayscale(1);
			filter: grayscale(1);
		}

		&.img-fluid {
			width: 100%;
			left: 0;
			transform: none;
		}
	}

	.hero-image-hub {
		margin: $gutter*3 auto;
		max-width: 100%;
		@include media-breakpoint-up(sm) {
			max-width: 30%;
		}
	}

	&--video {
		color: #fff;

		h1 {
			padding: 0;

			@include media-breakpoint-up(sm) {
				padding: $gutter*2 0;
			}
		}
	}

	&__underlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		z-index: -1;
	}

	&__content-wrapper {
		margin: 0 0 8rem;

		@media (min-width: 991px) {
			margin: 0 0 3rem;
		}
	}
}

