.think-gem-items {
	margin-bottom: 130px;
}

.blog-email-signup-form {
	font-size: 16px;
	padding: 0 30px;

	.form__row {
		margin-bottom: 25px;
	}

	.form__label {
		z-index: 1;
		margin-bottom: -35px;
		position: relative;
		margin-left: 10px;
		text-align: left;
	}

	.form__note {
		display: none;
	}

	.box-narrow {
		transition: 0.5s ease;
		max-height: 0;
		margin-bottom: 0 !important;
		padding: 0;
		overflow: hidden;
		opacity: 0;

		&.active {
			max-height: 350px;
			opacity: 1;
		}
	}

	.mc-field-group {
		margin-bottom: 25px;
	}

	#mc-embedded-subscribe {
		width: auto;
		border-radius: 1em;
		padding: 4.5px 19px;
		margin: auto;
	}
}

.search-bar {
	background: rgba(241, 240, 241, 1);
	margin: 0;
	transition: 0.3s ease;

	.button {
		min-height: 38px;
		width: 90%;
		margin-left: 10%;
		background-image: url('/img/search-icon.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: 20px;

		&:hover {
			background-image: url('/img/search-icon-gold.png');
			background-repeat: no-repeat;
			background-position: center;
			background-size: 20px;
		}
	}
	form {
		display: flex;
	}

	.form__row {
		&:first-of-type {
			width: 85%;
		}
		&:last-of-type {
			width: 15%;
		}
	}
	@media screen and (max-width: 630px) {
		form .form__row {
			&:first-of-type {
				width: 75%;
			}
			&:last-of-type {
				width: 25%;
			}
		}
	}
}
