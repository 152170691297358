.about-insights {
	.container {
		max-width: 1400px;
		padding: 0;
		overflow: hidden;
	}

	&__main-row {
		background: #4f575c;
		position: relative;

		&:first-of-type() {
			padding-right: 10px;

			@media screen and (max-width: 940px) {
				padding: 0;
			}
		}
	}

	&__image-wrapper {
		min-width: 100%;
		min-height: 100%;
		position: relative;
		overflow: hidden;
	}

	&__image {
		width: auto;
		height: auto;
		min-width: 100%;
		min-height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&__main-copy {
		color: #fff;
		position: relative;
		z-index: 5;
		padding: 25px 10px !important;
		text-align: center;

		&.column {
			width: 100%;
		}

		@media screen and (max-width: 940px) {
			padding: 1rem 2rem !important;
		}

		h3 {
			margin-top: 0;
			font-size: 22px;
		}
	}

	&__sub-copy {
		background: #fff;
		padding: 1rem 2rem;
		z-index: 5;
		position: relative;
		text-align: center;

		&::before {
			content: "";
			height: 40px;
			width: 40px;
			z-index: -1;
			transform: rotate(45deg) translate(-100%, 0%);
			position: absolute;
			left: calc(50% - 2px);
			top: 3px;
			background: #4f575c;
			display: none;
			
			@include media-breakpoint-up(lg) {
				display: block;
			}
		}
		
		h3 {
			font-size: 22px;
		}
	}

	&__sub-foot {
		position: relative;
		background: $brand-primary;

		&::after {
			content: "";
			height: 40px;
			width: 40px;
			z-index: 10;
			transform: rotate(45deg) translate(-100%, 0%);
			position: absolute;
			left: calc(50% - 2px);
			bottom: -62px;
			background: $lightgrey;
			display: none;
			
			@include media-breakpoint-up(lg) {
				display: block;
			}
		}
	}

	&__sub-foot-image {
		width: 100%;
		position: relative;
		z-index: 15;
	}

	&__sub-foot-link {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 25px;
		z-index: 20;
	}
}

// breaking out of the grid.
.columns-four > .about-insights__main-row.column {
	@media screen and (max-width: 940px) {
		width: calc(90% - 20px);
		margin: auto;
		display: block;
	}

	@media screen and (max-width: 767px) {
		width: 90%;
	}
}

.columns-four > .about-insights__sub-row.column {
	@media screen and (max-width: 940px) {
		width: 90%;
		margin: auto;
		display: block;
	}
}
