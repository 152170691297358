.cta-bar {
	&--copy {
		padding: 3rem 0 2.5rem;
	}

	&__copy {
		text-align: center;
		max-width: 650px;
		margin: auto;
	}

	&__heading {
		@include font-preset--2;

		margin-bottom: 11px;
	}

	&__intro {
		@include font-preset--7;

		margin-bottom: 0;
	}
}
