.article {
	.text-block figure img {
		max-width: 100%;
		float: none;
		margin: 0;
	}

	margin: 0 0 $gutter;
	@extend %clearfix;

	&:nth-last-of-type(2) {
		margin: 0 0 $gutter*4;
	}

	.article-title {
		text-transform: none;
		line-height: 1.2;
		@include resp-font-size(20, 20, 20, 24, 30);

		a {
			text-decoration: none;
		}
	}

	.article-meta {
		color: darken($lightgrey, 25%);
	}

	.image-overlay & {
		.article-meta {
			display: block;
		}

		p {
			display: none;
			@include media-breakpoint-up(xl) {
				display: block;
			}
		}
	}

	h2 {
		text-transform: inherit;
		font-weight: 600;
	}

	img {
		max-width: 100%;
		height: auto !important;
		@include media-breakpoint-down(sm) {
			display: block;
			margin: 0 auto $gutter;
		}

		@include media-breakpoint-up(sm) {
			max-width: 50%;
			float: right;
			margin: 0 0 $gutter $gutter;
		}

		&.infographic {
			float: none;
			margin: $gutter auto 0;
			max-width: 100%;
			display: block;
		}
	}
}
