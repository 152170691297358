.popover {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: rgba(255, 255, 255, .75);
	z-index: 1000;

	.popover-form {
		position: absolute;
		min-height: 348px;
		width: 300px;
		top: 50%;
		left: 50%;
		margin: -174px 0 0 -150px;
		background: #f1f0f1;
		border: 1px solid $grey;
		box-shadow: 0 0 10px rgba(0, 0, 0, .5);
		padding: 35px 18px 20px;
		color: $black;
		text-align: center;
	}

	.close {
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
		padding: 10px;
		line-height: 1;
	}

	label {
		display: block;
	}

	p {
		font-size: 1.1em;
		margin: 0 0 10px;
	}

	.form {
		list-style: none;

		li {
			margin: 0 0 5px;

			&:last-child {
				margin: 0;
			}
		}

		.mc-field-group {
			margin: 0 0 5px;
		}
	}

	input[type="text"] {
		width: 100%;
		margin: 0;
	}

	.button {
		padding: 8px 10px 6px;
	}
}
