.audit-cards {
	margin-bottom: 70px;

	&__section-heading {
		@include font-preset--2;
		text-align: center;
		margin-bottom: 42px;
	}

	&__columns {
		display: flex;
		flex-wrap: wrap;
		margin-right: -30px;
	}

	&__flip {
		width: calc(100% - 30px);
		margin: 0 30px 30px 0;

		@media (min-width: 320px) {
			width: calc(50% - 30px);
		}

		@media (min-width: 740px) {
			width: calc(25% - 30px);
		}
	}

	&__item {
		display: flex;
		height: 100%;
		background-color: #50585D;
		color: #fff;
		text-align: center;
		box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.22);
	}

	&__content {
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;
	}

	&__back {
		display: none;
		align-items: center;

		@media (min-width: 450px) {
			display: flex;
		}

		h4 {
			margin: 0;
		}
	}

	&__icon {
		max-width: 50px;
		margin-bottom: 5px;
	}

	&__heading {
		@include font-preset--6;
	}
}
