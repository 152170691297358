.text-block {
    &--center {
        text-align: center;
    }

    &--right {
        text-align: right;
    }

    &--intro {
        font-size: 23px;
    }

    &--case-studies {
        padding: 2rem 0;

        @include media-breakpoint-up(md) {
            padding: 4rem 0;
        }

        h2 {
            margin-bottom: 0;
        }

        + .vid-block, + .testimonials {
            @include media-breakpoint-up(md) {
                margin-top: -2rem;
            }
        }
    }
}

