html {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $brand-secondary;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

body {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.case-study-small-listing {
	.image-overlay {
		pointer-events: none;
	}

	a {
		cursor: pointer;
	}
}