.latest-news {
	.image-overlay {
		cursor: pointer;
		padding: 0px 0 100%;
	}

	h2 {
		@include font-preset--2;
	}
}

