.contact-map {
	position: relative;
	@include media-breakpoint-up(sm) {
		height: 60vw;
	}
	@include media-breakpoint-up(md) {
		height: 50vw;
	}
	@include media-breakpoint-up(lg) {
		height: 40vw;
	}
	@include media-breakpoint-up(xl) {
		height: 30vw;
	}

	.map-holder {
		height: 75vw;
		@include media-breakpoint-up(sm) {
			height: 100%;
		}
	}

	.map-address {
		@include media-breakpoint-up(sm) {
			z-index: 1000;
			background: $white;
			border-radius: 5px;
			padding: $gutter;
			position: absolute;
			top: $gutter;
			right: $gutter;
			width: 30%;
		}

		h4 {
			margin: 0 0 $gutter;
			text-transform: none;
		}
	}
}
