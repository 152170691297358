.service {
	.hero {
		@media (max-width: 767px) {
			padding: 0;
			min-height: 220px;

			.grayscale {
				right: 0;
				position: absolute;
				height: 100%;
				max-width: none;
				width: auto;
			}

			.overlay svg {
				left: -100%;
				right: -100%;
				width: 300%;
			}
		}
	}

	@media (min-width: 768px) {
		.columns-two .column {
			width: 50%;
			padding: 0 60px;
		}
	}
}
