.approach {
	background-color: $lightgrey;
	position: relative;
	padding: $gutter*7 0 $gutter*10;

	@include media-breakpoint-up(sm) {
		padding: 0;
	}

	&__img {
		visibility: hidden;
	}

	.container {
		position: absolute;
		top: $gutter*4;
		left: 50%;
		transform: translateX(-50%);
	}

	&--text-alt {
		color: $white;
	}

	&--no-img {
		&::after {
			content: "";
			background: no-repeat bottom center / 100% url("/img/hex-bg-black.png");
			opacity: 0.05;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			position: absolute;
		}

		@include media-breakpoint-up(sm) {
			padding: $gutter*4 0 $gutter*8;
		}

		.container {
			left: unset;
			margin: 0 auto;
			position: relative;
			top: unset;
			transform: none;
		}
	}
}
