.pricing {
	margin: $gutter 0;

	&__row {
		@include row(0);
	}

	&__col {
		text-align: center;
		color: white;
		@include col(12,0);

		@include media-breakpoint-up(sm) {
			@include col(6,0);
		}

		@include media-breakpoint-up(md) {
			@include col(3,0);
		}
	}

	&__heading {
		margin: 40px 0 15px;
	}

	&__copy {
		margin: 0 0 40px;
	}
}
