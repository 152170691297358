.benefits-cards {
    $green: #BACD52;
    $white: #FFFFFF;
    $grey: #F1F1F1;
    $darkGrey: #4F575B;

    margin-block-end: 3.75rem;

    img {
        width: 100%;
    }

    &__header {
        text-align: center;
        max-width: 58.75rem;
        margin: 2.5rem auto;
    }

    &__frame {
        position: relative;
        isolation: isolate;
        display: flex;
        flex-wrap: wrap;
        align-items: normal;
        justify-content: center;
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    .card {
        position: relative;
        z-index: 1;
        width: 100%;
        min-height: 25.8125rem;
        margin-block-end: 2.25rem;
        box-sizing: border-box;
        transition: transform 200ms ease;

        img {
            height: 15.625rem;
            object-fit: cover;
            object-position: center;
        }

        h3 {
            color: $green;
            margin-block: 0.5rem 0;
        }

        p {
            color: $white;
            margin-block: 0;
        }

        &__copy {
            min-height: calc(100% - 15.625rem);
            margin-block-start: -0.4375rem;
            padding: 1.375rem;
            background-color: $darkGrey;
            border-bottom-left-radius: 0.625rem;
            border-bottom-right-radius: 0.625rem;
            text-align: center;
        }

        .button {
            margin-block-start: 1rem;
        }

        &.active,
        &:hover {
            .button,
            .button:hover {
                background: transparent;
                color: $green;
            }
        }

        &::after {
            content: '';
            position: absolute;
            inset: auto auto -1.4375rem 50%;
            z-index: -1;
            width: 2.875rem;
            height: 2.875rem;
            background-color: $darkGrey;
            border-radius: 0.375rem;
            filter: drop-shadow(0px 5px 12px rgba(0,0,0,0.22));
            opacity: 0;
            transform: rotate(45deg) translate(-50%, 1.125rem);
            transition: opacity 200ms ease;
        }

        &.active {
            &::after {
                opacity: 1;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    .pane {
        position: relative;
        top: -3.875rem;
        z-index: -1;
        overflow: hidden;
        width: 100vw;
        height: 0px;
        transition: height 480ms linear;
        background-color: $grey;
        opacity: 0;
        transition: opacity 520ms ease;

        picture {
            width: 100%;
            aspect-ratio: 1/1;
        }

        img {
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        .container {
            display: flex;
            flex-direction: column;
            gap: 1.875rem;

            padding-block-start: 5.5rem;
            padding-block-end: 3.5rem;
        }

        &.active {
            height: fit-content;
            opacity: 1;
        }
    }

    @media only screen and (min-width: 48.75rem) {
        margin-block-end: 5rem;

        &__frame {
            display: grid;
            grid-template-columns: auto repeat(3, minmax(33%, 30.125rem)) auto;
            grid-template-rows: minmax(27.6875rem,auto) fit-content(58rem);
            grid-template-areas: "cards cards cards cards cards"
                                 "panes panes panes panes panes";
        }

        .card {
            width: calc(100% - 1.25rem);
            grid-area: cards;

            &:nth-of-type(1) {
                grid-column: 2;
            }

            &:nth-of-type(3) {
                grid-column: 3;
            }

            &:nth-of-type(5) {
                grid-column: 4;
            }

            &:hover,
            &.active {
                transform: scale(1.05);
            }

            &::after {
                transform: rotate(45deg) translate(-50%, 1.125rem);
            }
        }

        .pane {
            grid-area: panes;

            picture,
            &__copy {
                width: calc(50% - 1.25rem);
            }

            picture {
                max-width: 36.25rem;
            }

            &__copy {
                max-width: 33.125rem;
            }
            
            .container {
                flex-direction: row;
                justify-content: space-between;
                gap: 2.5rem;
                max-width: 74.375rem;
                padding-block-start: 8.5rem;
            }

            &.active {
                height: 48rem;
                opacity: 1;
            }
        }
    }
}