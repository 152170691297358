// Old CSS
@import '_legacy/libs/_normalize.scss';
@import '_legacy/libs/hamburgers.scss';
@import '_legacy/common/_functions.scss';
@import '_legacy/common/_mixins.scss';
@import '_legacy/common/_placeholders.scss';
@import '_legacy/common/_vars.scss';
@import '_legacy/base/_colours.scss';
@import '_legacy/base/_grid.scss';
@import '_legacy/base/_helpers.scss';
@import '_legacy/base/_structure.scss';
@import '_legacy/base/_typography.scss';
@import '_legacy/components/_about-insights.scss';
@import '_legacy/components/_accordion.scss';
@import '_legacy/components/_animation.scss';
@import '_legacy/components/_announcement-bar.scss';
@import '_legacy/components/_appBanner.scss';
@import '_legacy/components/_application-form.scss';
@import '_legacy/components/_approach.scss';
@import '_legacy/components/_article.scss';
@import '_legacy/components/_aspirations.scss';
@import '_legacy/components/_boldList.scss';
@import '_legacy/components/_border-box.scss';
@import '_legacy/components/_buttons.scss';
@import '_legacy/components/_circle.scss';
@import '_legacy/components/_cookie-bar.scss';
@import '_legacy/components/_copy-block.scss';
@import '_legacy/components/_events.scss';
@import '_legacy/components/_footer.scss';
@import '_legacy/components/_forms.scss';
@import '_legacy/components/_header.scss';
@import '_legacy/components/_hero.scss';
@import '_legacy/components/_hexes.scss';
@import '_legacy/components/_hgem-wheel.scss';
@import '_legacy/components/_introduction.scss';
@import '_legacy/components/_job-position.scss';
@import '_legacy/components/_jobListing.scss';
@import '_legacy/components/_lists.scss';
@import '_legacy/components/_map.scss';
@import '_legacy/components/_modal.scss';
@import '_legacy/components/_newsFeed.scss';
@import '_legacy/components/_overlay.scss';
@import '_legacy/components/_pagination.scss';
@import '_legacy/components/_popover.scss';
@import '_legacy/components/_quote.scss';
@import '_legacy/components/_results.scss';
@import '_legacy/components/_slick.scss';
@import '_legacy/components/_slider.scss';
@import '_legacy/components/_social-share-bar.scss';
@import '_legacy/components/_stars.scss';
@import '_legacy/components/_testimonials.scss';
@import '_legacy/components/_timeline.scss';
@import '_legacy/components/_tooltip.scss';
@import '_legacy/components/_videoContainer.scss';
@import '_legacy/layouts/_contact.scss';
@import '_legacy/layouts/_customers.scss';
@import '_legacy/layouts/_guest-home.scss';
@import '_legacy/layouts/_home.scss';
@import '_legacy/layouts/_press.scss';
@import '_legacy/layouts/_pricing.scss';
@import '_legacy/layouts/_service.scss';
@import '_legacy/layouts/_think-gem.scss';
@import '_legacy/layouts/_what-we-do.scss';

// Third party
@import '~@splidejs/splide/dist/css/splide.min.css';

/* import cans normalise/reset library */
// @import 'libs/normalize.scss';

/* import vars */
@import 'vars/animation.scss';
@import 'vars/breakpoints.scss';
@import 'vars/colours.scss';
@import 'vars/grid.scss';
@import 'vars/typography.scss';

/* import mixins */
@import 'mixins/breakpoints.scss';
@import 'mixins/grid.scss';
@import 'mixins/keyframes.scss';
@import 'mixins/typography.scss';

/* import components */
@import 'components/_alternating-block.scss';
@import 'components/_alternating-feature.scss';
@import 'components/_ccc.scss';
@import 'components/_contact-modal.scss';
@import 'components/_flip-card.scss';
@import 'components/audit-cards.scss';
@import 'components/benefits-cards.scss';
@import 'components/buttons.scss';
@import 'components/contact-cards.scss';
@import 'components/content-tabs.scss';
@import 'components/copy-columns.scss';
@import 'components/cta-bar.scss';
@import 'components/cta-hero.scss';
@import 'components/divider.scss';
@import 'components/filters.scss';
@import 'components/footer.scss';
@import 'components/header.scss';
@import 'components/hero.scss';
@import 'components/image-text-block.scss';
@import 'components/img-block.scss';
@import 'components/info-cards.scss';
@import 'components/info-graphic-block.scss';
@import 'components/intro-copy.scss';
@import 'components/latest-news.scss';
@import 'components/lazy-entries.scss';
@import 'components/modules.scss';
@import 'components/panes.scss';
@import 'components/pricing-tabs.scss';
@import 'components/pricing.scss';
@import 'components/quote-block.scss';
@import 'components/shimmer-card.scss';
@import 'components/square-slider.scss';
@import 'components/statistics.scss';
@import 'components/stats.scss';
@import 'components/tab-box.scss';
@import 'components/tabs.scss';
@import 'components/testimonials.scss';
@import 'components/text-block.scss';
@import 'components/ticklist.scss';
@import 'components/vid-block.scss';

/* import modules */
@import 'modules/example.scss';
@import 'modules/scroll-animation.scss';

/* import third party  styles */
@import 'third-party/example.scss';
@import 'third-party/splide.scss';

/* import base styles */
// @import 'base/animations.scss';
// @import 'base/base.scss';
// @import 'base/fonts.scss';
// @import 'base/helpers.scss';
// @import 'base/typography.scss';

/* import 'sins' file - when you need to put a quick hacky fix in */
// @import 'sins';
