.splide {
    .splide__pagination {

        &__page {
            background: $brand-secondary;

            &.is-active {
                background: $brand-primary;
            }
        }
    }
}

.testimonials {
    margin-bottom: 5rem;

    ul {
        padding: 0;
    }

    +.latest-news {
        margin-top: -3rem;
    }

    &__wrapper {

        .splide__list {
            margin: 2rem auto 3rem !important;

            @include media-breakpoint-up(md) {
                margin: 5rem auto 6rem !important;
            }
        }

        .splide__slide {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            img {
                height: auto;
                margin: 0 auto 1.6rem;
                max-width: 195px;
                width: 100%;
            }

            p {
                @include font-preset--5;

                margin-bottom: 2.1rem;
                max-width: 800px;
            }
        }

        .splide__pagination {
            transform: translateX(-50%)!important;
        }

    }

    &__caption {
    	@include font-preset--9;

    	width: 100%;
    	display: block;
    }

    &__button {
        font-size: 18px;
    }

}

.hero__hexes-slider--splide {
    .splide__slide {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 0;
        padding: 0 20px 3% 20px;

        img {
            max-width: 80%;
            max-height: 65%;
            max-width: 80%;
            max-height: 65%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
}