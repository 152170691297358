.stats {

    &__stat {
        display: inline-table;
        position: absolute;
        text-align: center;
        z-index: 1;
        min-width: 199px;
		top: 41px;
		right: -15px;

		&:nth-of-type(2) {
			top: 125px;
			right: 129px;
		}

		&:nth-of-type(3) {
			top: 209px;
			right: -15px;
		}

		@media (min-width: 550px) {
			top: 46px;
			right: -30px;
			transform: unset;

			&:nth-of-type(2) {
				top: 147px;
				right: 136px;
			}

			&:nth-of-type(3) {
				top: 244px;
				right: -30px;
			}
		}

		@media (min-width: 850px) {
			top: 46px;
			right: -30px;

			&:nth-of-type(2) {
				top: 147px;
				right: 136px;
			}

			&:nth-of-type(3) {
				top: 244px;
				right: -30px;
			}
		}

        @include media-breakpoint-up(sm) {
            min-width: 265px;
        }

		@include media-breakpoint-up(xl) {
			top: 46px;
			right: -30px;

			&:nth-of-type(2) {
				top: 159px;
				right: 155px;
			}

			&:nth-of-type(3) {
				top: 264px;
				right: -30px;
			}
		}

        
        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 130%;
            min-width: 210px;
            height: 210%;
            min-height: 150px;
            transform: translate(-50%,-50%);
            z-index: 1;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' width='200' height='174' viewbox='0 0 200 173.20508075688772' style='filter: drop-shadow(rgba(255, 255, 255, 0) 0px 0px 10px);'%3E%3Cpath fill='%234F575C' d='M5.000000000000001 95.26279441628824Q0 86.60254037844386 5.000000000000001 77.94228634059948L45 8.660254037844386Q50 0 60 0L140 0Q150 0 155 8.660254037844386L195 77.94228634059948Q200 86.60254037844386 195 95.26279441628824L155 164.54482671904333Q150 173.20508075688772 140 173.20508075688772L60 173.20508075688772Q50 173.20508075688772 45 164.54482671904333Z'/%3E%3C/svg%3E") center / contain no-repeat;
        
            @include media-breakpoint-up(md) {
                min-width: 190px;
            }

            @include media-breakpoint-up(xl) {
                height: 230%;
                min-width: 210px;
            }
        }

        &:before {
            top: 63%;
            left: 52%;
            filter: blur(14px);
            transform: translate(-50%, -50%) scale(0.92);
        }
    }

    &__stat-number, &__stat-text {
        position: relative;
        z-index: 2;
        color: $white;
    }

	&__stat-number {
        @include font-preset--3;

        line-height: 1;
        color: $brand-primary;
	}

	&__stat-text {
        @include font-preset--8;

        width: max(60%);
        margin: 7px auto 0;

        @media (max-width: 550px) {
            font-size: 12px;
        }
	}
}
