.think-gem .image-overlay,
.case-study .image-overlay {
	cursor: pointer;
}

.image-overlay {
	position: relative;
	cursor: default;
	padding: 0 0 83%;

	@include media-breakpoint-up(md) {
		padding: 0 0 100%;
	}

	@include media-breakpoint-up(lg2) {
		padding: 0 0 83%;
	}

	&--25 {
		padding: 0 0 67%;
	}

	&__img {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}

	&::after {
		content: '';
		width: 100%;
		height: 100%;
		display: block;
		//background: $brand-secondary;
		background: rgb(49, 49, 49);
		opacity: .6;
		position: absolute;
		top: 0;
		left: 0;
	}

	&.overlay-reverse {
		&::after {
			opacity: 0;
			transition: opacity 500ms;
		}

		.overlay-content {
			h3,
			h4,
			p {
				text-shadow: 0 0 6px rgba(0, 0, 0, 1);
			}
		}
	}

	&.image-grow {
		overflow: hidden;

		img {
			transition: transform 500ms;
		}
	}

	.overlay-content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		color: $white;
		z-index: 10;
		width: 95%;

		&.overlay-narrow {
			@include media-breakpoint-up(md) {
				width: 95%;
			}
			@include media-breakpoint-up(lg) {
				width: 75%;
			}
			@include media-breakpoint-up(xl) {
				width: 45%;
			}
		}

		&.overlay-two-thirds {
			top: 75%;
			opacity: 1;
			transition: opacity 500ms;

			h3,
			h4,
			p {
				margin: 0;
			}
		}

		&__play {
			width: 50px;
		}
	}

	.overlay-hover {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 0;
		transition: height 0.333s ease-out;
		background: $brand-primary;
		color: $white;
		z-index: 100;
		overflow: hidden;
		text-align: center;

		&.hover-transparent {
			background: transparent;
		}

		.hover-content {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 95%;

			&.hover-narrow {
				@include media-breakpoint-up(md) {
					width: 95%;
				}
				@include media-breakpoint-up(lg) {
					width: 75%;
				}
				@include media-breakpoint-up(xl) {
					width: 45%;
				}
			}
		}

		p {
			font-size: 14px;
		}

		&--gold {
			background: #b7ac93;
		}

		&--blue {
			background: $brand-mystery-guest-bg;
		}

		&--green {
			background: $brand-online-reviews-bg;
		}

		&--brown {
			background: #8e8878;
		}
	}

	.overlay-bg {
		&-gold {
			background: #BEAC64;
		}

		&-grey {
			background: #51575C
		}
	}

	.overlay-content,
	.overlay-hover {
		img {
			max-width: 50%;
			margin: 0 auto;

			@include media-breakpoint-up(md) {
				max-height: 100px;
			}
		}

		p {
			@include resp-font-size(14, 16, 14, 14, 16);
		}
	}

	&:hover {
		.overlay-hover {
			height: 100%;
		}

		&.overlay-reverse {
			&::after {
				opacity: .6;
				transition: opacity 500ms;
			}
		}

		&.image-grow {
			img {
				transition: transform 500ms;
				transform: scale(1.2);
			}
		}

		.overlay-two-thirds {
			opacity: 0;
			transition: opacity 500ms;
		}
	}

	&--noimg {
		&::after {
			border: 1px solid $brand-secondary;
			opacity: 1;
			background: transparent;
		}

		.overlay-content {
			color: $base-font-color;
			height: 100%;

			img {
				position: relative;
				top: 50%;
				transform: translateY(-50%);
			}

			p {
				width: 100%;
				text-align: center;
				position: absolute;
				bottom: 10px;
			}
		}

		.overlay-hover {
			background: $brand-secondary;

			h5 {
				text-transform: uppercase;
				margin: $gutter 0;
			}
		}
	}

	h3,
	h4 {
		text-transform: uppercase;

		&.ttn {
			text-transform: none;
		}
	}

	&__title {
		text-transform: uppercase;
		text-align: center;
	}
}

.integrations {
	&__columns {
		.column {
			width: 100%;

			@include media-breakpoint-up(sm) {
				width: 50%;
				padding: 0 10px;
			}

			@include media-breakpoint-up(md) {
				width: 25%;
				padding: 0 10px;
			}
		}
	}
}
