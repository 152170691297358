.border-box {
	border: 1px solid;
	margin: 0 auto $gutter*2;

	&--pad {
		border: 1px solid;
		padding: $gutter $gutter*2;
	}

	&--primary {
		border-color: $brand-primary;
	}
	
	&--secondary {
		border-color: $brand-secondary;
	}
	
	&--tertiary {
		border-color: $brand-tertiary;
	}
	
	&--quaternary {
		border-color: $brand-quaternary;
	}
	
	&--quintary {
		border-color: $brand-quintary;
	}

	.column {
		padding: $gutter $gutter*2;
	}
}