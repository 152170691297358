.statistics {
    overflow: hidden;
    background-image: url('/img/stat-bg-hexagon.png'),url('/img/stat-bg-hexagon.png'),url('/img/stat-bg-hexagon.png'),url('/img/stat-bg-hexagon.png'),url('/img/stat-bg-hexagon.png');
    background-size: 33vw, 7vw, 10vw, 10vw, 7vw;
    background-position: 0 -22vw, 48vw -3vw, 75vw -5vw, 81vw 18vw, 75vw 23.5vw;
    background-repeat: no-repeat;

    &__container {
        padding-top: 3rem;
        padding-bottom: 3rem;

        @media (min-width: 850px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__stats {
        max-width: 312px;
        width: 100%;
        min-height: 330px;
        position: relative;
        margin: 0 auto;
        padding: 0;

        &--size-1 {
            min-height: 158px;
            max-width: 167px;
        }

        &--size-2 {
            min-height: 240px;
        }

        @media (min-width: 550px) {
            max-width: 367px;
            min-height: 380px;

            &--size-1 {
                min-height: 200px;
                max-width: 204px;
            }

            &--size-2 {
                min-height: 280px;
            }
        }

        @media (min-width: 850px) {
            width: 45%;
            min-height: 380px;
            margin: unset;
            max-width: unset;

            &--size-1 {
                min-height: 180px;
            }

            &--size-2 {
                min-height: 280px;
            }
        }

        @include media-breakpoint-up(xl) {
            min-height: 400px;

            &--size-1 {
                min-height: 190px;
            }

            &--size-2 {
                min-height: 290px;
            }
        }
    }

    &__copy {
        max-width: 700px;
        text-align: center;
        margin: 2rem auto 0;

        @media (min-width: 850px) {
            text-align: left;
            max-width: unset;
            width: calc(55% - 3rem);
            margin: unset;
        }
    }
}