.videoContainer {
	position: relative;
	background: #fff;
	padding-bottom: 40%;
	overflow: hidden;
	max-width: 1080px;
	margin: 30px auto;

	&__video {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}

	@media (max-width: 720px) {
		padding-bottom: 60%;
	}
}
