@charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x                       : 0 !default;
$hamburger-padding-y                       : 0 !default;
$hamburger-layer-width                     : 40px !default;
$hamburger-layer-height                    : 4px !default;
$hamburger-layer-spacing                   : 6px !default;
$hamburger-layer-color                     : #fff !default;
$hamburger-layer-border-radius             : 4px !default;
$hamburger-hover-opacity                   : 1 !default;
$hamburger-hover-transition-duration       : 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter    : opacity(50%) !default;

// Base Hamburger (We need this)
// ==================================================
// Hamburger
// ==================================================
.hamburger {
	padding: $hamburger-padding-y $hamburger-padding-x;
	display: inline-block;
	cursor: pointer;
	transition-property: opacity, filter;
	transition-duration: $hamburger-hover-transition-duration;
	transition-timing-function: $hamburger-hover-transition-timing-function;

	// Normalize (<button>)
	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	margin: 0;
	overflow: visible;

	&:hover {
		@if $hamburger-hover-use-filter == true {
			filter: $hamburger-hover-filter;
		}
		@else {
			opacity: $hamburger-hover-opacity;
		}
	}

	@media (min-width: 992px) {
		display: none;
	}

}

.hamburger-box {
	width: $hamburger-layer-width;
	height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
	display: inline-block;
	position: relative;
	z-index: 101;
}

.hamburger-inner {
	display: block;
	top: 50%;
	margin-top: $hamburger-layer-height / -2;

	&,
	&::before,
	&::after {
		width: $hamburger-layer-width;
		height: $hamburger-layer-height;
		background-color: $hamburger-layer-color;
		border-radius: $hamburger-layer-border-radius;
		position: absolute;
		transition-property: transform;
		transition-duration: 0.15s;
		transition-timing-function: ease;
	}

	&::before,
	&::after {
		content: "";
		display: block;
	}

	&::before {
		top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
	}

	&::after {
		bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
	}
}

/*
	* Slider
	*/
.hamburger--slider {
	.hamburger-inner {
		top: $hamburger-layer-height / 2;

		&::before {
			top: $hamburger-layer-height + $hamburger-layer-spacing;
			transition-property: transform, opacity;
			transition-timing-function: ease;
			transition-duration: 0.2s;
		}

		&::after {
			top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
		}
	}

	&.is-active {
		.hamburger-inner {
			$y-offset: $hamburger-layer-spacing + $hamburger-layer-height;

			transform: translate3d(0, $y-offset, 0) rotate(45deg);

			background-color: #4B5459;

			&::before {
				transform: rotate(-45deg) translate3d($hamburger-layer-width / -7, $hamburger-layer-spacing * -1, 0);
				opacity: 0;
				background-color: #4B5459;
			}

			&::after {
				transform: translate3d(0, $y-offset * -2, 0) rotate(-90deg);
				background-color: #4B5459;
			}
		}
	}
}

// ==================================================
// Cooking up additional types:
//
// The Sass for each hamburger type should be nested
// inside an @if directive to check whether or not
// it exists in $hamburger-types so only the CSS for
// included types are generated.
//
// e.g. hamburgers/types/_new-type.scss
//
// @if index($hamburger-types, new-type) {
//   .hamburger--new-type {
//     ...
//   }
// }
