.vid-block {
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
        margin-bottom: 4rem;
    }

    &__wrapper {
        position: relative;
        height: 0;
        padding-bottom: 56.25%;
    }

    &__iframe {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    &--case-studies {
        + .copy-block {
            margin-top: 2rem;
            @include media-breakpoint-up(md) {
                margin-top: 4rem;
            }
        }
    }
}

.vid-block + .img-block {
    margin-top: 20px;
}