.aspirations {
	@include row();
	margin: $gutter 0;
	font-family: $exo;

	@include media-breakpoint-up(sm) {
		margin: $gutter -#{$gutter/2} $gutter;
	}

	&__column {
		@include set-column(12);

		@include media-breakpoint-up(md) {
			@include set-column(6);
		}
		// target width/(100/total cols) eg: 17.5/(100/12)
		@include media-breakpoint-up(lg) {
			@include set-column(2.1);
		}

		&--wide {
			@include set-column(12);

			@include media-breakpoint-up(lg) {
				@include set-column(3.6);
			}
		}
	}


	&__panel {
		height: 100%;
		text-align: center;

		&--grey {
			.aspirations__content {
				background: $brand-the-hub-light;
				color: $brand-the-hub-icon;

				&::after {
					background: $brand-the-hub-light;
				}
			}

			.aspirations__link {
				background: $brand-the-hub-bg;
			}

			.aspirations__icon {
				fill: $brand-the-hub-icon;
			}
		}

		&--green {
			.aspirations__content {
				background: $brand-online-reviews-light;
				color: $brand-online-reviews-icon;

				&::after {
					background: $brand-online-reviews-light;
				}
			}

			.aspirations__link {
				background: $brand-online-reviews-bg;
			}

			.aspirations__icon {
				fill: $brand-online-reviews-icon;
			}
		}

		&--yellow {
			.aspirations__content {
				background: $brand-guest-feedback-light;
				color: $brand-guest-feedback-icon;

				&::after {
					background: $brand-guest-feedback-light;
				}
			}

			.aspirations__link {
				background: $brand-guest-feedback-bg;
			}

			.aspirations__icon {
				fill: $brand-guest-feedback-icon;
			}
		}

		&--blue {
			.aspirations__content {
				background: $brand-mystery-guest-light;
				color: $brand-mystery-guest-icon;

				&::after {
					background: $brand-mystery-guest-light;
				}
			}

			.aspirations__link {
				background: $brand-mystery-guest-bg;
			}

			.aspirations__icon {
				fill: $brand-mystery-guest-icon;
			}
		}

		&--brown {
			.aspirations__content {
				background: $brand-employee-surveys-light;
				color: $brand-employee-surveys-icon;

				&::after {
					background: $brand-employee-surveys-light;
				}
			}

			.aspirations__link {
				background: $brand-employee-surveys-bg;
			}

			.aspirations__icon {
				fill: $brand-employee-surveys-icon;
			}
		}
	}

	&__content {
		padding: $gutter*2 $gutter;
		margin: $gutter/2 0 0;
		position: relative;

		p {
			font-size: 15px;
			margin: 0;
		}

		&::after {
			content: '';
			position: absolute;
			top: -12px;
			left: 50%;
			transform: translateX(-50%) rotate(45deg);
			width: 38px;
			height: 38px;
			z-index: 10;
		}
	}

	&__link {
		display: block;
		text-align: center;
		text-decoration: none;
		color: $white;
		padding: $gutter;
		position: relative;

		p {
			font-size: 22px;
			margin: 0;
		}

		&::after {
			content: '';
			position: absolute;
			background: $white;
			border-radius: 50%;
			width: 60px;
			height: 60px;
			left: 50%;
			transform: translateX(-50%);
			top: 20px;
		}
	}

	&__icon {
		height: 40px;
		margin: $gutter/2 auto $gutter;
		position: relative;
		z-index: 10;
	}
}
