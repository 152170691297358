.tabs {
  list-style-type: none;
  padding: 0;
  margin: 0;

  &--content {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 1.25rem;

    .tabs__item {
      width: auto;
      max-width: 14rem;
      flex-grow: 1;
    }
  }

  // Legacy
  &--legacy {
		border: 1px solid $brand-primary;

		.tab-controls {
			li {
				background: $brand-secondary;
				color: $white;
				padding: $gutter;
				cursor: pointer;
				display: block;
				@include media-breakpoint-up(md) {
					width: 20%;
					display: inline-block;
				}
				@include resp-font-size(18, 18, 16, 20, 24);

				&.active {
					background: $lightgrey;
					color: $brand-secondary;
				}
			}
		}

		.tab-content {
			background: $lightgrey;
			padding: 50px $gutter / 2;
			display: none;

			&:first-of-type {
				display: block;
			}

			@include media-breakpoint-down(md) {
				.columns {
					margin: 0;
				}
			}
		}
	}
}

.tab {
  &:hover {
    cursor: pointer;
  }

  &--btn {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 0.8rem;
    width: 100%;
    padding: 0.4rem 0.6rem 0.4rem 1rem;
    background: transparent;
    border-radius: 6.1875rem;
    border: 4px solid #697074;
    color: #697074;
    transition: background ease 300ms, color ease 300ms, border-color ease 300ms;
    transform-origin: center;

    span {
      width: calc(100% - (0.8rem + 2.75rem));
      font-family: "Exo", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.5;
      text-align: left;
    }

    svg {
      width: 2.375rem;
      transform: rotate(180deg);
      transition: transform ease 300ms;
    }

    &[data-active-tab="1"] {
      background: #B6CE35;
      border-color: #B6CE35;
      color: #ffffff;

      svg {
        transform: rotate(0deg);
      }
    }
  }
}