.job-position {
    .article {
        max-width: 75ch;
        margin: 0 auto;
        
        h2 {
            font-weight: 400;
    
            &:not(:first-of-type) {
                margin-top: $gutter*2;
            }
        }
    
        p {
            margin: $gutter*0.75 0;
        }
    
        ul {
            list-style: none;
            li {
                margin-bottom: $gutter/2;
    
                &:before {
                    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
                    color: $brand-primary; /* Change the color */
                    font-weight: bold; /* If you want it to be bold */
                    display: inline-block; /* Needed to add space between the bullet and the text */
                    width: 1em; /* Also needed for space (tweak if needed) */
                    margin-left: -1em; /* Also needed for space (tweak if needed) */
                }
            }
        }
    }

    .social-share-bar {
        max-width: 75ch;
        margin: 0 auto;
    }
}