$hex-background: rgba(#fff, 0.99999);
$hex-bg-base: 1px;
$hex-shadow-base: 4px;
$hex-shadow-bg: rgba(0, 0, 0, .25);

@mixin hex-size($width) {
	$widthPercent: ($width / 15);
	$height: $widthPercent * .9;

	width: $widthPercent + 0%;
	padding: $height + 0% 0 0;
}

.hex {
	text-align: center;
	position: relative;
	background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 18'%3E%3Cpath fill='#{$hex-background}' d='M6.26 18a2.84 2.84 0 0 1-2.21-1.27L.31 10.28a2.82 2.82 0 0 1 0-2.55l3.74-6.45A2.84 2.84 0 0 1 6.26.01h7.48a2.84 2.84 0 0 1 2.21 1.27l3.74 6.45a2.82 2.82 0 0 1 0 2.55l-3.74 6.45A2.84 2.84 0 0 1 13.74 18z'/%3E%3C/svg%3E");

	&::after {
		position: absolute;
		content: '';
		height: 110%;
		width: 110%;
		background: url("/img/hexagons-background.png") top center / cover no-repeat;
		top: -5%;
		left: -5%;
		opacity: 0.5;
		z-index: -1;
	}

	&.hex-xxs {
		@include hex-size(45);

		&::after {
			filter: blur($hex-bg-base);
		}

		&.hex-shadow {
			filter: blur($hex-shadow-base);
		}
	}

	&.hex-xs {
		@include hex-size(65);

		&::after {
			filter: blur($hex-bg-base);
		}

		&.hex-shadow {
			filter: blur($hex-shadow-base);
		}
	}

	&.hex-sm {
		@include hex-size(110);

		&::after {
			filter: blur($hex-bg-base + 1);
		}

		&.hex-shadow {
			filter: blur($hex-shadow-base + 1);
		}
	}

	&.hex-md {
		@include hex-size(115);

		&::after {
			filter: blur($hex-bg-base + 2);
		}

		&.hex-shadow {
			filter: blur($hex-shadow-base + 2);
		}
	}

	&.hex-lg {
		@include hex-size(161);

		&::after {
			filter: blur($hex-bg-base + 3);
		}

		&.hex-shadow {
			filter: blur($hex-shadow-base + 3);
		}
	}

	&.hex-xl {
		@include hex-size(271);

		&::after {
			filter: blur($hex-bg-base + 4);
		}

		&.hex-shadow {
			filter: blur($hex-shadow-base + 4);
		}
	}

	&.hex-border {
		opacity: .3;
		background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 18'%3E%3Cpath fill='transparent' stroke='#{$hex-background}' stroke-width='0.1' d='M6.26 18a2.84 2.84 0 0 1-2.21-1.27L.31 10.28a2.82 2.82 0 0 1 0-2.55l3.74-6.45A2.84 2.84 0 0 1 6.26.01h7.48a2.84 2.84 0 0 1 2.21 1.27l3.74 6.45a2.82 2.82 0 0 1 0 2.55l-3.74 6.45A2.84 2.84 0 0 1 13.74 18z'/%3E%3C/svg%3E");

		&::after {
			display: none;
		}
	}

	&.hex-shadow {
		&::after {
			display: none;
		}
	}

	.hex-content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		.cycle-slide:not(.cycle-sentinel) {
			position: absolute;
			top: 50% !important;
			transform: translateY(-50%) !important;
		}
	}
}

.columns--bullets {
	h6,
	h6 + p {
		padding: 0 0 0 $gutter;
	}

	h6 {
		margin: 0;
		position: relative;
		text-transform: uppercase;

		&::before {
			content: '';
			width: 8px;
			height: 25px;
			position: absolute;
			top: 0;
			left: 0;
			background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16.65 50'%3E%3Cpath d='M15.75 21.25L4.12 1.13A2.08 2.08 0 002.24 0a2.18 2.18 0 00-1.13.3 2.21 2.21 0 00-.82 3L12 23.5a3.48 3.48 0 010 3L.29 46.7a2.21 2.21 0 00.82 3 2.18 2.18 0 001.13.3 2.08 2.08 0 001.88-1.13l11.63-20.12a7.51 7.51 0 00.9-3.75 7.51 7.51 0 00-.9-3.75z' fill='%234f575d'/%3E%3C/svg%3E") 0 -1px / 8px 25px no-repeat;
		}

		+ p {
			margin: 0 0 $gutter;
		}
	}
}

.hex__bullet {
	// background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 18'%3E%3Cpath d='M6.26 18a2.84 2.84 0 0 1-2.21-1.27L.31 10.28a2.82 2.82 0 0 1 0-2.55l3.74-6.45A2.84 2.84 0 0 1 6.26.01h7.48a2.84 2.84 0 0 1 2.21 1.27l3.74 6.45a2.82 2.82 0 0 1 0 2.55l-3.74 6.45A2.84 2.84 0 0 1 13.74 18z'/%3E%3C/svg%3E") 0 1px / 20px 20px no-repeat;
	// padding: 0 0 0 30px;
	margin: 0 0 $gutter;

	//
	// REPLACED WITH CHEVRONS - HAVE LEFT OLD HEXAGON STYLING IN AS IT SEEMED A SHAME TO BIN.
	background-image: url('/img/icon-chevron.svg');
	background-size: 7px;
	background-repeat: no-repeat;
	background-position: 0 1px;
	padding: 0 0 0 20px;
	//
	//

	&:first-of-type {
		margin: $gutter*2 0 $gutter*1.5;
	}

	// &--grey {
	// 	background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 18'%3E%3Cpath fill='#{$brand-the-hub-bg}' d='M6.26 18a2.84 2.84 0 0 1-2.21-1.27L.31 10.28a2.82 2.82 0 0 1 0-2.55l3.74-6.45A2.84 2.84 0 0 1 6.26.01h7.48a2.84 2.84 0 0 1 2.21 1.27l3.74 6.45a2.82 2.82 0 0 1 0 2.55l-3.74 6.45A2.84 2.84 0 0 1 13.74 18z'/%3E%3C/svg%3E") 0 1px / 20px 20px no-repeat;
	// }
	//
	// &--green {
	// 	background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 18'%3E%3Cpath fill='#{$brand-online-reviews-bg}' d='M6.26 18a2.84 2.84 0 0 1-2.21-1.27L.31 10.28a2.82 2.82 0 0 1 0-2.55l3.74-6.45A2.84 2.84 0 0 1 6.26.01h7.48a2.84 2.84 0 0 1 2.21 1.27l3.74 6.45a2.82 2.82 0 0 1 0 2.55l-3.74 6.45A2.84 2.84 0 0 1 13.74 18z'/%3E%3C/svg%3E") 0 1px / 20px 20px no-repeat;
	// }
	//
	// &--yellow {
	// 	background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 18'%3E%3Cpath fill='#{$brand-guest-feedback-bg}' d='M6.26 18a2.84 2.84 0 0 1-2.21-1.27L.31 10.28a2.82 2.82 0 0 1 0-2.55l3.74-6.45A2.84 2.84 0 0 1 6.26.01h7.48a2.84 2.84 0 0 1 2.21 1.27l3.74 6.45a2.82 2.82 0 0 1 0 2.55l-3.74 6.45A2.84 2.84 0 0 1 13.74 18z'/%3E%3C/svg%3E") 0 1px / 20px 20px no-repeat;
	// }
	//
	// &--blue {
	// 	background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 18'%3E%3Cpath fill='#{$brand-mystery-guest-bg}' d='M6.26 18a2.84 2.84 0 0 1-2.21-1.27L.31 10.28a2.82 2.82 0 0 1 0-2.55l3.74-6.45A2.84 2.84 0 0 1 6.26.01h7.48a2.84 2.84 0 0 1 2.21 1.27l3.74 6.45a2.82 2.82 0 0 1 0 2.55l-3.74 6.45A2.84 2.84 0 0 1 13.74 18z'/%3E%3C/svg%3E") 0 1px / 20px 20px no-repeat;
	// }
	//
	// &--brown {
	// 	background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 18'%3E%3Cpath fill='#{$brand-employee-surveys-bg}' d='M6.26 18a2.84 2.84 0 0 1-2.21-1.27L.31 10.28a2.82 2.82 0 0 1 0-2.55l3.74-6.45A2.84 2.84 0 0 1 6.26.01h7.48a2.84 2.84 0 0 1 2.21 1.27l3.74 6.45a2.82 2.82 0 0 1 0 2.55l-3.74 6.45A2.84 2.84 0 0 1 13.74 18z'/%3E%3C/svg%3E") 0 1px / 20px 20px no-repeat;
	// }

	h6 {
		text-transform: uppercase;
		margin: 0;
	}

	p {
		margin: 0;
	}

	img {
		float: right;
		margin: 0 0 20px 20px;
		max-width: 30%;
	}
}
