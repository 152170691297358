header {
	border-bottom: 5px solid $brand-primary;
	background: $brand-secondary;
	color: $white;
	padding: 0 $gutter;
	display: flex;
	font-family: $exo;
	position: relative;
	@include media-breakpoint-down(md) {
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}

	.logo {
		margin: 28px 0;
		flex: 0 0 75%;
		max-width: 200px;

		@include media-breakpoint-up(sm) {
			flex: 0 0 45%;
		}
		@include media-breakpoint-up(md) {
			flex: 0 0 30%;
		}
		@include media-breakpoint-up(lg) {
			flex: 0 0 22.5%;
		}
		@include media-breakpoint-up(xl) {
			flex: 0 0 18%;
		}
		@include media-breakpoint-up(xxl) {
			flex: 0 0 15%;
		}
	}

	.nav-toggle {
		cursor: pointer;
	}

	nav {
		text-align: center;
		padding: 0 0 26px;

		.header-buttons {
			margin-left: 20px !important;
			float: right;

			li a {
				padding: 10px 0;

				&:hover {
					opacity: 0.9;
				}
			}
		}

		ul {
			&:last-of-type {
				clear: both;
			}

			&.support-links {
				float: right;
			}
		}

		@media screen and (max-width: 991px) {
			.header-buttons,
			ul.support-links {
				float: none;
			}

			.header-buttons {
				margin: 0 0 20px 0!important;
			}

			ul.support-links li:first-of-type {
				color: #4f575c;
			}
		}

		@include media-breakpoint-down(md) {
			position: absolute;
			top: 100%;
			left: 20px;
			display: none;
			width: 100vw;
			margin: 0 -#{$gutter};
			background: rgba(255, 255, 255, .9);
			z-index: 1000000;
		}
		@include media-breakpoint-up(lg) {
			flex: 0 0 77.5%;
			text-align: right;
			display: block !important;
			flex-direction: column;
			justify-content: space-between;
		}
		@include media-breakpoint-up(xl) {
			flex: 0 0 82%;
		}
		@include media-breakpoint-up(xxl) {
			flex: 0 0 85%;
		}

		.header-buttons {
			margin: 0 0 $gutter;
			@include media-breakpoint-up(lg) {
				margin: 0 0 35px;
			}

			li {
				margin: 0 2px;
				font-size: 14px;
				@include media-breakpoint-up(lg) {
					margin: 0 0 0 4px;
				}

				&:first-of-type {
					a {
						background: $brand-primary;
					}
				}
			}

			a {
				text-align: center;
				background: $brand-tertiary;
				color: $white;
				width: 136px;
				padding: 3px 0;
				text-decoration: none;
				display: inline-block;
				@include media-breakpoint-up(lg) {
					width: 140px;
					border-radius: 0 0 3px 3px;
				}
			}
		}

		.header-menu {
			margin: 0 0 10px;

			li {
				position: relative;
				font-size: 18px;
				@include media-breakpoint-down(md) {
					display: block;
				}
				@include media-breakpoint-up(lg) {
					margin: 0 0 0 30px;
				}

				&.social {
					@include media-breakpoint-up(lg) {
						margin: 0 0 0 20px;
						position: relative;
						top: 8px;
					}

					svg {
						width: 30px;
						height: 30px;
						transition: background 500ms;
						background: 0;

						path,
						circle {
							fill: rgba(241, 240, 241, 0.8);
						}

						&:hover {
							transition: background 500ms;
							opacity: 0.6;
						}
						@media screen and (max-width: 991px) {
							path,
							circle {
								fill: rgba(79, 87, 92, 1);
							}
						}
					}
				}
			}

			a {
				text-decoration: none;
				transition: color 500ms;
				color: $base-font-color;
				@include media-breakpoint-up(lg) {
					color: $white;
				}

				&:hover,
				&.active {
					color: $brand-primary;
					transition: color 500ms;
				}
			}
		}
	}

	.guest-buttons {
		display: none;
	}
}

.nav__item {
	&--dropdown {
		&:hover {
			.dropdown {
				display: block;
			}
		}
	}
	@media (max-width: 990px) {
		padding-bottom: 0.3rem;
	}
}

.dropdown {
	position: absolute;
	top: 27px;
	left: -1rem;
	text-align: left;
	padding: 1.25rem 1rem 0.8rem;
	z-index: 100;
	width: 300px;
	display: none;
	color: #4B5459;
	box-shadow: inset 0px 2px 0px $brand-primary;

	@media (min-width: 990px) {
		&::before {
			content: "";
			position: absolute;
			top: 2px;
			left: 0;
			width: 100%;
			height: 100%;
			background: #F1F1F1;
		}
	}

	.dropdown__item {
		display: block;
		padding: 0;
		margin: 0 0 6px;
		margin-left: 0;

		a {
			color: #4B5459;
		}

		&--heading {
			opacity: 0.7;
			font-size: 1rem;

			@media (max-width: 992px) {
				color: #4F575C;
			}
		}

		&--indent {
			@media (max-width: 992px) {
				padding-left: 0.5rem;
			}
		}

		&--spacer {
			border: 0;
			margin-top: 1rem;
		}
	}

	@media (max-width: 990px) {
		position: relative;
		display: block;
		text-align: center;
		top: unset;
		left: unset;
		margin: auto;
		border: 0;
		background: transparent;
		padding: 0;

		.dropdown__item {
			font-size: 15px;
		}
	}

	&--mega {
		@media (min-width: 990px) {
			width: 800px;
			left: calc(-400px + 50%);
			padding: 1.5rem;

			.dropdown-wrapper {
				display: flex;
				justify-content: space-between;
				padding: 0;
			}

			.dropdown__column {
				width: calc(33.33% - 1rem);
				margin: 0;
			}

			.dropdown__column-list {
				padding: 0;
			}

			.dropdown-thumbnail {
				display: block;
				width: 100%;
				margin-bottom: 0.25rem;
			}
		}
	}
}

.nav-accordion-button {
	display: none;
	position: absolute;
	right: 0;
	top: 0;
	width: 2rem;
	height: 2rem;
	appearance: none;
	background: unset;
	border: unset;
	cursor: pointer;
	padding: 0;
	margin: 0;
	background-image: url('/img/icon-plus-green.png');
	background-position: 100% 50%;
	background-repeat: no-repeat;
	background-size: 1.1rem;

	&.active {
		background-image: url('/img/icon-minus-green.png');
	}
}

@media (max-width: 990px) {

	.js-nav-accordion {
		> a {
			width: 100%;
			display: block;
			padding-right: 2rem;
		}

	}

	.nav-accordion-button {
		display: block;
	}

	.js-nav {
		&.is-active {
			+.mobile-target-class {
				pointer-events: all;
				opacity: 1;
			}
		}
	}

	.mobile-target-class {
		pointer-events: none;
		opacity: 0;
		top: 0;
		z-index: 100;
		background-color: #F1F1F1;
		padding: 7rem 2rem 8rem 2rem;
		display: flex;
		flex-direction: column;
		text-align: left;
		min-height: 100vh;

		.header-menu {
			> .nav__item {
				padding-bottom: 0.5rem;
				margin-top: 0.5rem;
				border-bottom: 1px solid #D0D2D3;
			}
		}

		.support-links {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			text-align: center;
		}

		.header-buttons {
			position: absolute;
			bottom: 3rem;
			left: 0;
			width: 100%;
			text-align: center;
		}

		.dropdown {
			width: unset;
			text-align: left;
			box-shadow: unset;
			padding-bottom: 1rem;
			border-top: unset;
		}

		.dropdown-wrapper {
			padding: 0;
		}

		.dropdown__column-list {
			padding: 0;
		}

		.dropdown__item--indent {
			padding-left: 0;
		}

		.dropdown-thumbnail {
			display: none;
		}

		.nav__item--dropdown {
			position: relative;
		}

		.dropdown__item {
			margin: 0.5rem 0 0 0;

			&--heading {
				margin-top: 1rem;
			}
		}
	}
}