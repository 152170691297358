.stars {
	li {
		position: relative;
		font-size: 40px;
		margin: 0 5px;
		@include media-breakpoint-up(sm) {
			font-size: 50px;
		}

		&::before {
			transition: color 500ms;
			content: '\2605';
			color: transparent;
		}

		&.loading {
			&::before {
				transition: color 500ms;
				color: $brand-secondary;
			}
		}

		&.active {
			&::before {
				transition: color 500ms;
				color: $brand-primary;
			}
		}
	}
}
