.img-block {
    float: left;

    figure {
        display: flex;
        margin: 0 0 20px;
    }

    a,
    img {
        float: inherit;
        max-width: 100%;
        margin: 0;
    }
}

