.modules {
	margin: $gutter 0;

	&__row {
		@include row();
	}

	&__col {
		@include col(12);

		@include media-breakpoint-up(sm) {
			@include col(6);
		}

		@include media-breakpoint-up(md) {
			@include col(3);
		}
	}

	&__box {
		display: block;
		background: white;
		text-align: center;
		padding: $gutter;
		transition: background $transition;
		text-decoration: none;

		&:hover {
			background: $brand-primary;
			transition: background $transition;

			.modules__icon {
				path {
					fill: $white;
					transition: fill $transition;
				}
			}

			.modules__link {
				color: $white;
				transition: color $transition;
			}
		}
	}

	&__icon {
		width: 80px;
		height: 80px;

		path {
			transition: fill $transition;
			fill: $brand-primary;
		}
	}

	&__link {
		color: $brand-primary;
		transition: color $transition;
	}
}


