$cta-hero-b-1: 950px;
$cta-hero-b-2: 730px;

.cta-hero {
	position: relative;
	background-color: #F1F0F1;
	padding: 5rem 0;
	min-height: 60vh;
	margin-bottom: 3rem;

	&__background {
		display: none;

		@media (min-width: $cta-hero-b-2) {
			display: block;
			position: absolute;
			z-index: 1;
			top: 0;
			right: 0;
			width: 50%;
			height: 100%;
			background-size: cover;
			background-position: 50%;
			background-color: #cacaca;
		}
	}

	&__container {
		position: relative;
		z-index: 2;
		display: flex;
		flex-wrap: wrap;
	}

	&__text {
		width: 100%;
		padding: 0 0 1rem 0;
		text-align: center;

		@media (min-width: $cta-hero-b-2) {
			padding: 0 calc(50% + 20px) 2rem 0;
			text-align: left;
		}
	}

	&__heading {}

	&__copy {}

	&__cta {
		width: 100%;
		margin: 2rem 0 0;
		background-color: #fff;
		text-decoration: none;
		border-radius: 4px;
		overflow: hidden;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-height: 230px;
		box-shadow: 3px 3px 8px rgba(0,0,0,0.1);
		

		@media (min-width: $cta-hero-b-2) {
			width: calc(45% - 32px);
			margin: 0 32px 0 0;
			min-height: unset;
		}

		@media (min-width: $cta-hero-b-1) {
			width: calc(36% - 32px);
		}
		
		&:nth-of-type(2) {
			.cta-hero__cta-button {
				background-color: #AABEBF;
				color: #4f575c;
			}
		}
	}

	&__cta-text {
		padding: 32px 32px 0;
		z-index: 2;
		position: relative;
	}

	&__cta-heading {
		margin: 0;
		font-weight: 600;
	}

	&__cta-copy {
		margin-top: 2ex;
		font-size: 14px;
	}

	&__cta-button {
		background-color: #B6CE35;
		color: #fff;
		text-align: center;
		z-index: 2;
		position: relative;
		margin-top: 2.2ex;

		p {
			margin: 0;
			padding: 1.25ex;
			font-size: 18px;
		}
	}

	&__cta-icon {
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		aspect-ratio: 1;
		background-size: cover;
		background-position: 50%;
		right: -120px;
		width: 300px;
		opacity: 0.08;
	}
}
