/* Placeholders */

/* Placeholder: Clearfix
Usage:
.foo {
	@extend %clearfix;
}
*/

%clearfix {
	*zoom: 1;

	&::before,
	&::after {
		content: ' ';
		display: table;
	}

	&::after {
		clear: both;
	}
}
