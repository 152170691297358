.image-text-block {
    float: left;
    &__fig {
        width: 100%;
        margin: 0;

        @include media-breakpoint-up(md) {
			width: auto;
            max-width: 50%;
            float: right;
            margin: 0 0 20px 20px;

            &--rev {
                float: left;
                margin: 0 20px 20px 0;
            }
		}
    }

	&__img {
		&#{&} {
			max-width: 100%;
			width: 100%;
			float: inherit;
			margin: 0;
		}
	}
}
