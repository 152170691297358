.js-fade-in-row {
	@media (min-width: 1000px) {
		animation-name: fadeIn;
		animation-fill-mode: forwards;
		animation-duration: 1s;
		animation-delay: 0.7s;
		opacity: 0;

		&--hidden {
			> * {
				opacity: 0;
				transform: translateY(30px);
				transition: all ease 0.8s;
				transition: transform 0.8s ease, opacity 0.8s ease;
			}
		}

		&--visible {
			transform: translateY(0);
			opacity: 1;

            > * {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}
}


@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
