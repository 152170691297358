.introduction {
	margin: 0 0 -#{$gutter*3};

	&__img {
		mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 106 94.78'%3E%3Cpath d='M104.7 52.6a11.48 11.48 0 0 0 1.3-5.21 11.16 11.16 0 0 0-1.3-5.21l-.2-.3L83.86 6l-.4-.7A11.19 11.19 0 0 0 73.94 0H32.06a11.09 11.09 0 0 0-9.72 5.71l-.1.2L1.4 42l-.1.2A11.48 11.48 0 0 0 0 47.39a10.2 10.2 0 0 0 1.2 4.91l.7 1.2 20.44 35.37.1.2a11.11 11.11 0 0 0 9.72 5.71h41.78a10.92 10.92 0 0 0 9.42-5.31l.4-.7L104.5 52.9z' fill='%23010101'/%3E%3C/svg%3E");
		mask-repeat: no-repeat;
		position: relative;
		z-index: 10;
	}
}
