.cookie-bar {
	display: none;
	font-size: 0;
	background-color: $base-font-color;
	color: $white;
	padding: 10px 45px 10px 15px;
	z-index: 9999;
	position: relative;

	p {
		font-size: 0.87rem;
		display: inline-block;
		vertical-align: middle;
		margin: 0;
	}

	a {
		color: $white;
	}

	&__close {
		width: 40px;
		height: 100%;
		padding: 0;
		appearance: none;
		cursor: pointer;
		background: #f1f1f1;
		border: 0;
		position: absolute;
		top: 0;
		right: 0;

		&:after {
			position: absolute;
			content: "X";
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 19px;
			font-weight: 600;
			color: #4f575c;
			font-family: "Exo-2", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
		}
	}
}
