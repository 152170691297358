.boldList {
	padding: 0;
	margin: 0;
	list-style: none;

	&__item {
		margin-bottom: 15px;
		font-size: 1rem;
		font-weight: 400;
		padding-left: 1rem;
		position: relative;

		&::before {
			content: '•';
			position: absolute;
			left: 0;
			top: 1px;
			color: $brand-primary;
		}
	}
}
