footer {
	text-align: center;
	padding: $gutter;
	background: $brand-secondary;
	color: $white;
	@include media-breakpoint-up(md) {
		display: flex;
		justify-content: space-between;
		text-align: left;
	}

	a {
		color: $white;
		text-decoration: none;
	}

	li {
		font-size: 15px;
		margin: 0 14px 0 0;
		position: relative;

		&::after {
			position: absolute;
			content: '•';
			top: 50%;
			right: -9px;
			transform: translateY(-50%);
		}

		&:last-of-type {
			&::after {
				display: none;
			}
		}
	}

	p {
		font-size: 15px;
		margin: 0;
	}
}
