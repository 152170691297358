.square-slider {
	margin: 2rem 0 6rem;

	&__content {
		color: #fff;
		background-color: #4F575C;
		padding: 3rem 1.8rem;
		background-repeat: no-repeat;
		background-image: url('/img/why-bg-05.svg'),
		url('/img/why-bg-04.svg');
		background-position: 7% -10%,
		81% 106%;
	}
	
	&__section-heading {
		@include font-preset--2;

		margin-bottom: 2.6rem;
		padding: 0 1rem;
	}

	&__text {
		margin-bottom: 3rem;
	}

	&__heading {
		@include font-preset--5;
	}

	&__copy {
		@include font-preset--7;
	}

	&__image {
		padding-bottom: 25px;
		

		img {
			border-top: 6px solid #B7CF36;
			max-width: 100%;
			display: block;
			box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
		}
	}

	.swiper {
		overflow: hidden visible;
		padding: 0 1rem;
	}

	.slick-dots {
		position: relative;
		top: auto;
		left: auto;
		bottom: auto;
		display: flex;
		width: 100%;
		max-width: 300px;
		margin-top: 1.8rem;

		span {
			width: 0.7rem;
			height: 0.7rem;
			font-size: 0;
			margin-right: 0.8rem;
			border-radius: 100%;
			overflow: hidden;
			background-color: #EFEFEF;
			opacity: 1;

			&.swiper-pagination-bullet-active {
				background-color: #B7CF36;
			}
		}
	}

	@media only screen and (min-width: 700px) {
		&__content {
			background-image: url('/img/why-bg-01.svg'),
			url('/img/why-bg-02.svg'),
			url('/img/why-bg-03.svg'),
			url('/img/why-bg-04.svg'),
			url('/img/why-bg-05.svg'),
			url('/img/why-bg-06.svg');
			background-position: 2% -28%,
			-4% 85%,
			55% -12%,
			47% 115%,
			110% 0,
			93% 110%;
		}

		&__slide {
			display: flex !important;
			flex-wrap: wrap;
			justify-content: space-between;
			gap: 2.5rem;
			padding-inline: 0;
		}

		&__text {
			width: calc(50% - 1.25rem);
			margin-bottom: 0;
		}

		&__image {
			width: calc(50% - 1.25rem);
		}

		.slick-dots {
			position: absolute;
			margin: 0;
		}
	}

	@media only screen and (min-width: 1000px) {
		&__content {
			padding: 5rem 7.5rem;
		}

		&__text {
			padding-top: 4.8rem;
		}

		&__copy {
			margin-bottom: 8rem;
		}

		.swiper {
			margin: -4.8rem 0 -8.5rem;
		}

		.slick-dots {
			position: absolute;
			bottom: 5.5rem!important;
			padding-inline-start: 1rem;
		}
	}
}
