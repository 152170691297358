.form {
	padding: $gutter 0;

	&--job {
		max-width: 75ch;
		margin: 0 auto;
	
		.form__field {
			display: flex;
			flex-flow: column;
			margin-bottom: $gutter;

			label {
				color: $brand-secondary;
			}

			input,
			textarea {
				border: 1px solid $grey-400;
				padding: $gutter/4 $gutter/2;
			}

			input[type=file] {
				padding: 0;
				border: 0;

				&::-webkit-file-upload-button {
					visibility: hidden;
				}
				&::before {
					content: 'Choose files';
					display: inline-block;
					color: $white;
					background: $brand-secondary;
					border-radius: $gutter*1.5;
					padding: $gutter/3 $gutter;
					outline: none;
					white-space: nowrap;
					-webkit-user-select: none;
					cursor: pointer;
					margin-right: -$gutter*4;
				}
				&:hover::before {
					border-color: black;
					background: lighten($brand-secondary, 10%);
				}
				&:active::before {
					background: lighten($brand-secondary, 20%);
				}
			}

			input[type=submit] {
				display: inline-block;
				color: $white;
				background-color: $brand-primary;
				border-radius: $gutter*1.5;
				border: 0;
				max-width: 190px;
				cursor: pointer;
				margin-top: $gutter;
			}

		}

		.success {
			background: $success-green;
			color: $white;
			padding: 0.5rem 1rem;
			margin-bottom: 1rem;
			text-align: center;
			border-radius: 5px;
		}
	}

	fieldset {
		border: 0;
		margin: 0;
		padding: 0;
	}

	label,
	input {
		display: block;
	}

	input,
	textarea,
	select {
		width: 100%;
	}

	label {
		margin: 0 0 5px;
	}

	input,
	textarea {
		padding: 5px;
		border-radius: 5px;
		border: 2px solid $brand-tertiary;
	}

	input[type="checkbox"] {
		display: inline-block;
		width: auto;
		margin: 0 10px 0 0;
		position: relative;
		top: -2px;

		& + label {
			display: none;
		}
	}

	.form__row,
	.form__note {
		margin: 0 0 10px;
	}

	.form__row--checkboxes {
		.form__label {
			display: inline-block;
		}

		.form__field {
			float: left;
		}
	}
}
