.copy-columns {
	&__heading {
		@include font-preset--2;

		margin-bottom: 2.5rem;

		@media (min-width: 500px) {
			margin-bottom: 4rem;
		}
	}

	.column {
		h3 {
			@include font-preset--6;

			font-weight: 800;
			margin-bottom: 3px;
			position: relative;
			padding-left: 25px;

			@media (min-width: 500px) {
				padding-left: 35px;
			}

			+p {
				padding-left: 0;

				@media (min-width: 500px) {
					padding-left: 35px;
				}
			}

			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 3px;
				width: 1.1rem;
				height: 1.1rem;
				background-image: url('/img/round-tick.png');
				background-repeat: no-repeat;
				background-size: contain;

				@media (min-width: 500px) {
					top: 5px;
					width: 1.5rem;
					height: 1.5rem;
				}
			}
		}

		p {
			@include font-preset--7;

			+h3 {
				margin-top: 2rem;

				@media (min-width: 500px) {
					margin-top: 3rem;
				}
			}
		}
	}
}