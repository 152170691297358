.results {
	background: $lightgrey;
	padding: $gutter*4 0;
}

.result {
	background: $white;
	margin-bottom: $gutter;
	padding: $gutter;

	&__result {
		text-align: center;
		font-family: $exo;
		@include resp-font-size(48, 24, 28, 36, 48);
	}
}
