
.hero--hex-container {
	+.intro-copy {
		margin-top: 2.5rem;
	}
}

.hero {
	+.intro-copy {
		margin-top: 1.25rem;
	}
}

.intro-copy {
	margin-bottom: 1rem;

	+.cta-bar {
		margin-top: -2rem;
	}

	&__heading {
		@include font-preset--5;

		text-align: center;
	}

	p {
		@include font-preset--6;
		text-align: center;
	}
}

