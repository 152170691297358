.our-customers {

	main {
		ul {
			li {
				position: relative;
				list-style: none;
				&::before {
					content: ""; 
					position: absolute; 
					left: -20px; 
					top: 5px; 
					width: 6px;
					height: 12px;
					border: solid $brand-primary;
					border-width: 0 2px 2px 0;
					-webkit-transform: rotate(45deg);
					-moz-transform: rotate(45deg);
					-o-transform: rotate(45deg);
					transform: rotate(45deg);
				}
			}
		}
		ul.slick-dots {
			li {
				&::before {
					display: none;
				}
			}
		}
	}

	.container {
		ul.list-buttons.list-inline li {
			margin: 0 5px 10px;
			display: inline-block;
		}
		@media screen and (max-width: 567px) {
			ul.list-buttons.list-inline li span {
				font-size: 1em;
				padding: 4.5px 14px;
			}
		}
	}

	.columns-three .column {
		@media screen and (max-width: 1020px) {
			width: 50%;
		}
		@media screen and (max-width: 767px) {
			width: 100%;
		}
	}

	.alternating-block__modules-item,
	.splide__slide,
	.splide__pagination li {
		&:before {
			display: none;
		}
	}
}
