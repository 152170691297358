.wheel {
	&__wrap {
		display: inline-block;
		width: 100%;
		vertical-align: middle;
		padding: 0 2rem;
		text-align: center;

		@include media-breakpoint-up(md) {
			max-width: 450px;
		}

		& > div {
			max-width: 458px;
			margin: auto;
		}
	}

	&__copy {
		display: inline-block;
		width: 100%;
		vertical-align: middle;
		padding: 0 2rem;

		@include media-breakpoint-up(md) {
			max-width: calc(100% - 450px);
		}
	}
}
