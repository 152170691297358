.circle-container {
	position: relative;
}

.circle-overlay {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 100;
}

.circle {
	max-width: 618px;
	width: 100%;
	overflow: visible !important;
	margin: 2rem auto;
	display: block;
	position: relative;
	height: 320px;

	@include media-breakpoint-up(md) {
		height: auto;
	}

	.circle-active {
		cursor: pointer;
	}

	.circle-light {
		fill: $white;

		> path {
			&:first-child {
				fill: lighten($brand-mystery-guest-bg, 10%);
			}
		}
	}

	.circle-dark {
		fill: $white;

		> path {
			&:first-child {
				fill: $brand-mystery-guest-bg;
			}
		}
	}

	.circle-heart {
		fill: $white;

		path {
			&:first-child {
				fill: $brand-the-hub-icon;
			}
		}
	}

	.circle-icon {
		fill: $brand-mystery-guest-icon;
	}

	&--grey {
		.circle-bg {
			fill: $brand-the-hub-bg;
		}

		.circle-icon {
			fill: $brand-the-hub-icon;
		}

		&:hover {
			.circle-bg {
				fill: darken($brand-the-hub-bg, 10%);
			}

			.circle-icon {
				fill: darken($brand-the-hub-icon, 10%);
			}
		}
	}

	&--green {
		.circle-bg {
			fill: $brand-online-reviews-bg;
		}

		.circle-icon {
			fill: $brand-online-reviews-icon;
		}

		&:hover {
			.circle-bg {
				fill: darken($brand-online-reviews-bg, 10%);
			}

			.circle-icon {
				fill: darken($brand-online-reviews-icon, 10%);
			}
		}
	}

	&--yellow {
		.circle-bg {
			fill: $brand-guest-feedback-bg;
		}

		.circle-icon {
			fill: $brand-guest-feedback-icon;
		}

		&:hover {
			.circle-bg {
				fill: darken($brand-guest-feedback-bg, 10%);
			}

			.circle-icon {
				fill: darken($brand-guest-feedback-icon, 10%);
			}
		}
	}

	&--blue {
		.circle-bg {
			fill: $brand-mystery-guest-bg;
		}

		.circle-icon {
			fill: $brand-mystery-guest-icon;
		}

		&:hover {
			.circle-bg {
				fill: darken($brand-mystery-guest-bg, 10%);
			}

			.circle-icon {
				fill: darken($brand-mystery-guest-icon, 10%);
			}
		}
	}

	&--brown {
		.circle-bg {
			fill: $brand-employee-surveys-bg;
		}

		.circle-icon {
			fill: $brand-employee-surveys-icon;
		}

		&:hover {
			.circle-bg {
				fill: darken($brand-employee-surveys-bg, 10%);
			}

			.circle-icon {
				fill: darken($brand-employee-surveys-icon, 10%);
			}
		}
	}

	.cls-1,
	.cls-2,
	.cls-3,
	.cls-4,
	.cls-10 {
		cursor: pointer;
	}

	.cls-1 {
		fill: $white;

		path {
			&:first-child {
				fill: $brand-mystery-guest-bg;
			}
		}
	}

	.cls-2 {
		fill: $white;

		path {
			&:first-child {
				fill: #8e8878;
			}
		}
	}

	.cls-3 {
		fill: $white;

		path {
			&:first-child {
				fill: $brand-online-reviews-bg;
			}
		}
	}

	.cls-4 {
		fill: $white;

		path {
			&:first-child {
				fill: #b7ac93;
			}
		}
	}

	.cls-6 {
		fill: $white;

		path {
			&:first-child {
				fill: #aabebf;
			}
		}
	}

	.cls-7 {
		fill: $white;

		path {
			&:first-child {
				fill: #c5c1b3;
			}
		}
	}

	.cls-8 {
		fill: $white;

		path {
			&:first-child {
				fill: #c8d1c5;
			}
		}
	}

	.cls-9 {
		fill: $white;

		path {
			&:first-child {
				fill: #e1d7bd;
			}
		}
	}

	.cls-10 {
		fill: $white;

		path {
			&:first-child,
			&:nth-child(2) {
				fill: #939c9f;
			}
		}
	}

	&.circle-hover {
		.circle-light.circle-active {
			&:hover {
				path {
					&:first-child {
						fill: $brand-mystery-guest-bg;
					}
				}
			}
		}

		.circle-dark.circle-active {
			&:hover {
				path {
					&:first-child {
						fill: darken($brand-mystery-guest-bg, 10%);
					}
				}
			}
		}

		.circle-heart {
			&:hover {
				path {
					&:first-child {
						fill: darken($brand-the-hub-icon, 10%);
					}
				}
			}
		}

		.cls-1 {
			&:hover {
				path {
					&:first-child {
						fill: darken($brand-mystery-guest-bg, 10%);
					}
				}
			}
		}

		.cls-2 {
			&:hover {
				path {
					&:first-child {
						fill: darken(#8e8878, 10%);
					}
				}
			}
		}

		.cls-3 {
			&:hover {
				path {
					&:first-child {
						fill: darken($brand-online-reviews-bg, 10%);
					}
				}
			}
		}

		.cls-4 {
			&:hover {
				path {
					&:first-child {
						fill: darken(#b7ac93, 10%);
					}
				}
			}
		}

		.cls-10 {
			&:hover {
				path {
					&:first-child,
					&:nth-child(2) {
						fill: darken(#939c9f, 10%);
					}
				}
			}
		}
	}

	&.circle-expand {
		max-width: 500px;
		margin: 0 auto $gutter;

		.circle-sector-1,
		.circle-sector-2,
		.circle-sector-3,
		.circle-sector-4 {
			opacity: 0;
			transition: all 500ms;
		}

		.circle-sector-1,
		.circle-sector-3 {
			transform: translateX(-150px);
		}

		.circle-sector-2,
		.circle-sector-4 {
			transform: translateX(150px);
		}

		.circle-sector-animated {
			transform: translateX(0);
			opacity: 1;
			transition: all 500ms;
		}

		.circle-centre {
			transform: scale(0);
			transform-origin: center;
			transition: all 500ms;
		}

		.circle-centre-animated {
			transform: scale(1);
			transform-origin: center;
			transition: all 500ms;
		}
	}

	&.circle-captionless {
		opacity: .5;

		g {
			path:not(:first-child) {
				display: none;
			}
		}
	}
}
