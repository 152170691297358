.tooltip-trigger {
	position: relative;
	margin: $gutter/2 0 $gutter/2 $gutter;
	cursor: pointer;

	.tooltip-content {
		display: inline;
		position: relative;
		border-bottom: 1px dashed;

		&::before {
			content: '';
			position: absolute;
			top: 2px;
			left: -#{$gutter};
			width: $gutter;
			height: $gutter;
			background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16.65 50'%3E%3Cpath d='M15.75 21.25L4.12 1.13A2.08 2.08 0 0 0 2.24 0a2.18 2.18 0 0 0-1.13.3 2.21 2.21 0 0 0-.82 3L12 23.5a3.48 3.48 0 0 1 0 3L.29 46.7a2.21 2.21 0 0 0 .82 3 2.18 2.18 0 0 0 1.13.3 2.08 2.08 0 0 0 1.88-1.13l11.63-20.12a7.51 7.51 0 0 0 .9-3.75 7.51 7.51 0 0 0-.9-3.75z' fill='%234f575d'/%3E%3C/svg%3E") left center / contain no-repeat;
		}
	}

	.tip-container {
		display: none;
		width: 120%;
		@include media-breakpoint-only(md) {
			width: 200%;
		}
	}

	&:hover {
		.tip-container {
			display: block;
		}
	}
}

.tip-container {
	pointer-events: none;
	position: absolute;
	background: $white;
	padding: $gutter;
	border-radius: $gutter;
	box-shadow: 0 0 10px rgba(0, 0, 0, .5);
	width: 100%;
	z-index: 1000;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.tooltip__list {
	display: flex;
	flex-wrap: wrap;


	li {
		margin: 0 $gutter/2 $gutter/2;
		position: relative;
		width: calc(33.333% - 30px);

		&::before {
			content: '';
			position: absolute;
			top: 2px;
			left: -#{$gutter};
			width: $gutter;
			height: $gutter;
			background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16.65 50'%3E%3Cpath d='M15.75 21.25L4.12 1.13A2.08 2.08 0 0 0 2.24 0a2.18 2.18 0 0 0-1.13.3 2.21 2.21 0 0 0-.82 3L12 23.5a3.48 3.48 0 0 1 0 3L.29 46.7a2.21 2.21 0 0 0 .82 3 2.18 2.18 0 0 0 1.13.3 2.08 2.08 0 0 0 1.88-1.13l11.63-20.12a7.51 7.51 0 0 0 .9-3.75 7.51 7.51 0 0 0-.9-3.75z' fill='%234f575d'/%3E%3C/svg%3E") left center / contain no-repeat;
		}

		@media (max-width: 768px) {
			width: 100%;
		}
	}
}

.addonTooltip {
	display: inline-block;
	position: relative;

	p {
		display: inline;
		border-bottom: 1px dashed;
	}

	&__content {
		display: none;
    pointer-events: none;
    position: absolute;
    background: #fff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    width: 90%;
    top: 97%;
    left: -16px;
    z-index: 1000;
	}

	&:hover {
		.addonTooltip__content {
			display: block;
		}
	}
}
