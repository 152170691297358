html {
	color: $base-font-color;
	font: #{$base-font-size}/1.5 $sans-serif;
}

@include declare-font-face('Exo', 'exo2-medium-webfont', '400');

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $exo;
	font-weight: 400;
}

h1,
h2,
h3 {
	text-transform: uppercase;
}

.ttn {
	text-transform: none;
}

h1,
.h1 {
	@include resp-font-size(20, 28, 36, 44);

	margin: 0 0 $gutter;
}

h2,
.h2 {
	@include resp-font-size(24, 24, 28, 36);

	margin: 0 0 $gutter;
}

h3,
.h3 {
	@include resp-font-size(20, 20, 24, 30);

	margin: $gutter 0;
}

h4,
.h4 {
	@include resp-font-size(20);

	margin: $gutter 0;
}

h5,
.h5 {
	@include resp-font-size(18);
}

h6,
.h6 {
	@include resp-font-size(16);
}

p {
	font-size: $base-font-size;
	margin: $gutter/2 0;
}

.text-sm {
	font-size: 80%;
}

.border {
	border-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 3'%3E%3Ccircle fill='rgba(#{red($brand-primary)}, #{green($brand-primary)}, #{blue($brand-primary)}, .99999)' cx='1.5' cy='1.5' r='1.5'/%3E%3C/svg%3E") 3 repeat;
	border-style: solid;
	border-width: 3px;
	padding: 50px;
}

a {
	color: $base-font-color;
}

.paragraph-large {
	font-size: 17px;
	margin-bottom: 30px;

	p {
		font-size: 17px;
	}
}

.hex-overlay {
	position: relative;

	&--grey {
		path {
			fill: #929b9f;
		}
	}

	&--green {
		path {
			fill: #91a38b;
		}
	}

	&--yellow {
		path {
			fill: #ccb376;
		}
	}

	&--blue {
		path {
			fill: #7a9393;
		}
	}

	&--brown {
		path {
			fill: #b7ac97;
		}
	}
	
	h2 {
		position: absolute;
		width: 100%;
		text-align: center;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		color: $white;
	}
}
