.panes {
  list-style-type: none;
  padding: 0;
  margin: 2.5rem 0;

  &--content {
    background-color: #F1F0F1;
    padding: 2rem 1.25rem;
  }

  &:empty {
    display: none;
  }

  @media only screen and (min-width: 768px) {
    &--content {
      padding-inline: 1.25rem;
    }
  }

  @media only screen and (min-width: 1512px) {
    &--content {
      padding-inline: 5.5rem;
    }
  }
}

.pane {
  &--content {
    height: 0;
    overflow: hidden;
    transition: opacity 300ms ease;

    h2,
    h3 {
      width: 100%;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.4;
      text-align: center;
      text-transform: none;
    }

    .pane__content {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      gap: 1.25rem;
      width: 100%;
    }

    .tab-box {
      width: 100%;
      flex: 1 0 19.9375rem;
    }

    &[data-active-pane="1"] {
      height: auto;
      opacity: 1;
    }
  }

  @media only screen and (min-width: 1024px) {
    &--content {
      .tab-box {
        flex-basis: calc(24% - 0.416rem);
        max-width: 19.9375rem;
      }
    }
  }
}