.timeline {
	margin-bottom: 120px !important;
	text-align: center;

	img {

		max-width: 0px;

		@include media-breakpoint-up(md) {
			transition: all 0.3s ease;
			width: 75%;
			max-width: 150px;
			margin: 0 auto 170px;
			opacity: 0.3;
		}
	}

	span {
		opacity: 0;
	}

	.slick-current {
		background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 3 10'%3E%3Ccircle fill='rgba(#{red($brand-tertiary)}, #{green($brand-tertiary)}, #{blue($brand-tertiary)}, .99999)' cx='1.5' cy='1.5' r='1.5'/%3E%3C/svg%3E") center / 3px 10px repeat-y;
		cursor: default;
		position: relative;

		img {
			width: 100%;
			max-width: 250px;
			margin: 0 auto 300px;
			opacity: 1;

			@include media-breakpoint-up(md) {
				margin: 0 auto 110px;
				transition: all 0.3s ease;
			}

			@include media-breakpoint-down(sm) {
				position: relative;
				width: 300%;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		span {
			opacity: 1;
			position: absolute;
			left: -100%;
			bottom: 4rem;
			width: 300%;
			transform: translateY(100%);

			@include media-breakpoint-up(sm) {
				bottom: 6rem;
			}

			@include media-breakpoint-up(md) {
				bottom: -4rem;
			}
		}
	}

	.timeline-years {
		position: relative;
		background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 3'%3E%3Ccircle fill='rgba(#{red($brand-tertiary)}, #{green($brand-tertiary)}, #{blue($brand-tertiary)}, .99999)' cx='1.5' cy='1.5' r='1.5'/%3E%3C/svg%3E") 50% 17.5px / 10px 3px repeat-x;
		margin: -357px 0 190px;

		span {
			width: 100%;
			position: relative;
			left: 0;
			bottom: 0;
			opacity: 1;
		}

		@media (min-height: 445px){
			margin: -294px 0 100px;
		}

		@media (min-height: 485px){
			margin: -230px 0 100px;
		}

		@include media-breakpoint-up(sm) {
			margin: -210px 0 100px;
		}

		@include media-breakpoint-up(md) {
			margin: -400px 0 100px;
		}

		@media (min-width: 853px) {
			margin: -347px 0 100px;
		}

		@include media-breakpoint-up(lg) {
			margin: -265px 0 100px;
		}

		@media (min-width: 1100px) {
			margin: -210px 0 100px;
		}
	}

	.timeline-year {
		cursor: pointer;
		position: relative;
		padding: 45px 0 0;
		transition: padding 500ms;

		&::after {
			content: '';
			position: absolute;
			top: 4.5px;
			left: 50%;
			width: 30px;
			height: 27px;
			transform: translateX(-50%);
			background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 18'%3E%3Cpath fill='rgba(#{red($brand-secondary)}, #{green($brand-secondary)}, #{blue($brand-secondary)}, .99999)' d='M6.26 18a2.84 2.84 0 0 1-2.21-1.27L.31 10.28a2.82 2.82 0 0 1 0-2.55l3.74-6.45A2.84 2.84 0 0 1 6.26.01h7.48a2.84 2.84 0 0 1 2.21 1.27l3.74 6.45a2.82 2.82 0 0 1 0 2.55l-3.74 6.45A2.84 2.84 0 0 1 13.74 18z'/%3E%3C/svg%3E");
			transition: height 500ms, width 500ms;
		}

		&.slick-current {
			font-weight: 700;
			font-size: 20px;
			padding: 80px 0 0;
			transition: padding 500ms;

			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 50%;
				width: 40px;
				height: 36px;
				transform: translateX(-50%);
				background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 18'%3E%3Cpath fill='rgba(#{red($brand-primary)}, #{green($brand-primary)}, #{blue($brand-primary)}, .99999)' d='M6.26 18a2.84 2.84 0 0 1-2.21-1.27L.31 10.28a2.82 2.82 0 0 1 0-2.55l3.74-6.45A2.84 2.84 0 0 1 6.26.01h7.48a2.84 2.84 0 0 1 2.21 1.27l3.74 6.45a2.82 2.82 0 0 1 0 2.55l-3.74 6.45A2.84 2.84 0 0 1 13.74 18z'/%3E%3C/svg%3E");
				transition: height 500ms, width 500ms;
			}
		}
	}

	.timeline-controls {
		top: 78.8%;
		@include media-breakpoint-up(sm) {
			top: 79%;
		}
		@include media-breakpoint-up(md) {
			top: 77.5%;
		}
		@include media-breakpoint-up(lg) {
			top: 78.8%;
		}

		.slider-prev,
		.slider-next {
			opacity: 1;
			background-color: $white;
			border-radius: 50%;
		}
	}
}
